import { Inject, Injectable } from "@angular/core";
import axios, { AxiosResponse } from "axios";
import { getFirebaseBackend, firebase } from "../../authUtils";
import { UserX } from "../models/users";
import { AuthenticationService } from "./auth.service";
import { CompanyEventsService } from "./company-events.service";
import * as pako from "pako";
import zlib from "zlib";
import { FormService } from "./form.service";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ElasticsearchService } from "./elasticsearch.service";
import { AwsSnsService } from "./aws-sns.service";
import { RoomsService } from "./rooms.service";
import { MindeeService } from "./mindee.service";
@Injectable({
  providedIn: "root",
})
export class SubmitService {
  constructor(
    private authService: AuthenticationService,
    private eventService: CompanyEventsService,
    private fromService: FormService,
    private http: HttpClient,
    private elasticSearchService: ElasticsearchService,
    private awsSnsService: AwsSnsService,
    private roomsService: RoomsService,
    private mindeeService : MindeeService
  ) { }
  getSubmitsDocuments() {
    let submitRef = firebase.firestore().collection("/submits/");

    return submitRef;
  }

  getSubmitsByEvents() {
    let user = this.authService.currentUserValue;
    let event = user.event;
    let forms = [];
    let events = [];
    return new Promise<any>((resolve, reject) => {
      this.eventService.getEventsByUser().then((res: any) => {
        return new Promise<any>((resolve, reject) => {
          res.forEach(async (element) => {
            await firebase
              .firestore()
              .collection("/submits/")
              .where("eventsIDs", "array-contains", element.uid)
              .get()
              .then((result) => {
                result.forEach((data) => {
                  let form = data.data();
                  forms.push(form);
                });
                events.push(element);
                if (res.length == events.length) resolve(forms);
              });
          });
        }).then(() => {
          resolve(forms);
        });
      });
    });
  }
  getSubmitByEmail1(email) {
    return firebase
      .firestore()
      .collection("/submits/")
      .where("data.personalInformation1.emailAddress", "==", email)
      .get();
  }
  getSubmitByEmail(email) {
    return firebase
      .firestore()
      .collection("/submits/")
      .where("data.personalInformation1.emailAddress", "==", email)
      .get();
  }

  getSubmitByCustom(custom,value) {
    const jsonEvent = localStorage.getItem('event')
    const event = JSON.parse(jsonEvent)
    console.log(custom, "==", value)
    return firebase
      .firestore()
      .collection("/submits/")
      .where(custom, "==", value)
      .where('eventID', "==", event.uid)
      .get();
  }

  getSubmitsByEventID(id) {
    let user = this.authService.currentUserValue;
    let event = user.event;
    let forms = [];
    let events = [];
    return new Promise<any>((resolve, reject) => {
      firebase
        .firestore()
        .collection("/submits/")
        .where("eventID", "==", id)
        .where("status", "!=", "moved")
        .get()
        .then((result) => {
          result.forEach((data) => {
            let form = data.data();
            if (!form.status || form.status == "") {
              form.status = "pending";
            }
            forms.push(form);
          });
          resolve(forms);
        });
    });
  }

  getSubmitsByRoomID(id) {
    let forms = [];
    return new Promise<any>((resolve, reject) => {
      firebase
        .firestore()
        .collection("/submits/")
        .where("data.personalInformation1.room", "==", id)
        .get()
        .then((result) => {
          result.forEach((data) => {
            let form = data.data();
            if (!form.status || form.status == "") {
              form.status = "pending";
            }
            if(!form?.deleted){

              forms.push(form);
            }
          });
          resolve(forms);
        });
    });
  }

  getApprovedSubmitsByRoomID(id) {
    let forms = [];
    return new Promise<any>((resolve, reject) => {
      firebase
        .firestore()
        .collection("/submits/")
        .where("data.personalInformation1.room", "==", id)
        .where("status", "==", 'approved')
        .get()
        .then((result) => {
          result.forEach((data) => {
            let form = data.data();
            if (!form.status || form.status == "") {
              form.status = "pending";
            }
            if(!form?.deleted){

              forms.push(form);
            }
          });
          resolve(forms);
        });
    });
  }

  getBookingsSubmitsByEventID(id) {
    let user = this.authService.currentUserValue;
    let event = user.event;
    let forms = [];
    let events = [];
    return new Promise<any>((resolve, reject) => {
      firebase
        .firestore()
        .collection("/submits/")
        .where("eventID", "==", id)
        .get()
        .then((result) => {
          result.forEach((data) => {
            let form = data.data();
            if (!form.status || form.status == "") {
              form.status = "pending";
            }
            if(!form?.deleted){

              forms.push(form);
            }
          });
          resolve(forms);
        });
    });
  }
  getBookingsSubmitsByEventIDSnap(id) {
    let user = this.authService.currentUserValue;
    let event = user.event;
    let forms = [];
    let events = [];
    return new Promise<any>((resolve, reject) => {
      firebase
        .firestore()
        .collection("/submits/")
        .where("eventID", "==", id)
        .onSnapshot((snapshot) => {
          snapshot.forEach((data) => {
            let form = data.data();
            if (!form.status || form.status == "") {
              form.status = "pending";
            }
            if(!form?.deleted){

              forms.push(form);
            }
          });
          resolve(forms);
        });
    });
  }

  getSubmitsByRoomIDSnap(id) {
    let user = this.authService.currentUserValue;
    let event = user.event;
    let forms = [];
    let events = [];
    return new Promise<any>((resolve, reject) => {
      firebase
        .firestore()
        .collection("/submits/")
        .where("data.room", "==", id)
        .onSnapshot((snapshot) => {
          forms = [];
          snapshot.forEach((data) => {
            let form = data.data();
            if (!form.status || form.status == "") {
              form.status = "pending";
            }
            if (!form?.deleted) {
              forms.push(form);
            }
          });
          resolve(forms);
        }, (error) => {
          alert(error); // Handle errors
        });
    });
  }


  async getLimitedSubmitsByFormID(id, pageSize, lastVisible,filter) {
    // let response = await this.elasticSearchService.getTestData();

    let user = this.authService.currentUserValue;
    let event = user.event;
    let forms = [];
    return new Promise<any>((resolve, reject) => {
      let query = firebase
        .firestore()
        .collection("/submits/")
        .where("formID", "==", id)
        .orderBy("createdAt", "desc")
        .limit(pageSize);

      if (lastVisible) {
        query = query.startAfter(lastVisible);
      }

      query.get().then((result) => {
   
        result.forEach((data) => {
          let form = data.data();
          console.log(filter)
          if(filter === 'Deleted'){
            console.log(form?.deleted === true)
              if(form?.deleted === true){
          
                forms.push(form);
              }
          }
          else {
            if(filter === 'Current'){
              if (form.status != "moved" &&(form.deleted != true )) {
           
                forms.push(form);
              }
            }
            else {
              forms.push(form);
            }
          }
         
        });
        let lastVisible = result.docs[result.docs.length - 1];
        forms["lastVisible"] = lastVisible;
        resolve(forms);
      });
    });
  }
  async getLimitedSubmitsByEventID(id, pageSize, lastVisible) {
    let response = await this.elasticSearchService.getTestData();

    let user = this.authService.currentUserValue;
    let event = user.event;
    let forms = [];
    return new Promise<any>((resolve, reject) => {
      let query = firebase
        .firestore()
        .collection("/submits/")
        .where("eventsIDs", "array-contains", id)
        .orderBy("createdAt", "desc")
        .limit(pageSize);

      if (lastVisible) {
        query = query.startAfter(lastVisible);
      }

      query.get().then((result) => {
        result.forEach((data) => {
          let form = data.data();
          if (form.status != "moved" && form.deleted != true) {
            forms.push(form);
          }
        });
        let lastVisible = result.docs[result.docs.length - 1];
        forms["lastVisible"] = lastVisible;
        resolve(forms);
      });
    });
  }
  async getData(collection, fieldToFilter, filterValue) {
    let Axios = axios.create({
      baseURL: "https://us-central1-diggri.cloudfunctions.net",
      timeout: 45000,
    });

    return Axios.post("/fetchAndCompressSubmitsData", {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": "true",
      },
      body: {
        collection: collection,
        fieldToFilter: fieldToFilter,
        filterValue: filterValue,
      },
      collection: collection,
      fieldToFilter: fieldToFilter,
      filterValue: filterValue,
    });
  }
  async getSubmitsByFormID(id) {
    return this.getData("submits", "formID", id);
  }

  uint8ArrayToJson(uint8Array: Uint8Array): any {
    const decoder = new TextDecoder("utf-8");
    const stringData = decoder.decode(uint8Array);

    try {
      const jsonData = JSON.parse(stringData);

      return jsonData;
    } catch (error) {
      // Handle error
    }
  }
  Uint8ArrayToString(uint8Array: Uint8Array): string {
    let string = "";
    for (let i = 0; i < uint8Array.length; i++) {
      string += String.fromCharCode(uint8Array[i]);
    }
    return string;
  }
  arrayBufferToJson(arrayBuffer: ArrayBuffer): any {
    const uint8Array = new Uint8Array(arrayBuffer);
    const jsonString = new TextDecoder().decode(uint8Array);
    const jsonData = JSON.parse(jsonString);
    return jsonData;
  }
  fetchAndCompressSubmitsData(
    collection: string,
    fieldToFilter: string,
    filterValue: string
  ): Observable<any> {
    const requestData = {
      collection: collection,
      fieldToFilter: fieldToFilter,
      filterValue: filterValue,
    };

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.http
      .post(
        "https://us-central1-diggri.cloudfunctions.net/fetchAndCompressSubmitsData",
        requestData,
        httpOptions
      )
      .pipe(
        map((compressedData: any) => {
          try {
            return compressedData;
          } catch (error) {
            throw new Error("Failed to decompress or parse the response data.");
          }
        }),
        catchError((error) => {
          console.error("An error occurred:", error);

          return throwError("Failed to fetch and compress data.");
        })
      );
  }

  async fetchArrayBuffer(url) {
    try {
      const response = await fetch(url, { method: "GET" });

      if (response.ok) {
        const arrayBuffer = await response.arrayBuffer();
        return arrayBuffer;
      } else {
        throw new Error("Request failed with status: " + response.status);
      }
    } catch (error) {
      throw error;
    }
  }

  async sendEmail(data, type, formdata, eventData,uid) {
    console.log(data)
    return new Promise<any>(async (resolve, reject) => {
      let Axios = axios.create({
        baseURL: "https://us-central1-diggri.cloudfunctions.net",
        timeout: 45000,
      });
      let templateID = "";
      let subject = "";
      let message = ""
      let cc = null
      if(data?.language ==='ar'){
        if (
          type == "approve" &&
          (!formdata?.approvalidar || formdata?.approvalidar == "")
        ) {
          resolve("empty");
        }
        if (
          type == "reject" &&
          (!formdata?.rejectionidar || formdata?.rejectionidar == "")
        ) {
          resolve("empty");
        }
        if (
          type == "register" &&
          (!formdata?.registrationidar || formdata?.registrationidar == "")
        ) {
          resolve("empty");
        }
        if (
          type == "badgecollected" &&
          (!formdata?.badgecollectedid || formdata?.badgecollectedid == "")
        ) {
          resolve("empty");
        }
        if (
          type == "badgecollection" &&
          (!formdata?.badgecollectionid || formdata?.badgecollectionid == "")
        ) {
          resolve("empty");
        }
      }
      else {
        if (
          type == "approve" &&
          (!formdata?.approvalid || formdata?.approvalid == "")
        ) {
          resolve("empty");
        }
        if (
          type == "reject" &&
          (!formdata?.rejectionid || formdata?.rejectionid == "")
        ) {
          resolve("empty");
        }
        if (
          type == "register" &&
          (!formdata?.registrationid || formdata?.registrationid == "")
        ) {
          resolve("empty");
        }
        if (
          type == "badgecollected" &&
          (!formdata?.badgecollectedid || formdata?.badgecollectedid == "")
        ) {
          resolve("empty");
        }
        if (
          type == "badgecollection" &&
          (!formdata?.badgecollectionid || formdata?.badgecollectionid == "")
        ) {
          resolve("empty");
        }
      }
    
      switch (type) {
        case "approve":
          {
            cc =  formdata.ccApproval? formdata.ccApproval.map((email) => (email.displayValue)):null,
            subject = data?.language ==='ar'?formdata?.approvalsubjectar: formdata?.approvalsubject;
            message =  formdata?.approvalmessagesubject;
            templateID =  data?.language ==='ar'?formdata?.approvalidar :  formdata?.approvalid;
            if (!formdata?.approvalid) {
              return;
            }
          }
          break;
        case "reject":
          {
            cc = formdata.ccRejection? formdata.ccRejection.map((email) => email.displayValue):null
            subject =  data?.language ==='ar'?formdata?.rejectionsubjectar :  formdata?.rejectionsubject;
            message = formdata?.rejectionmessagesubject;
            templateID = data?.language ==='ar'?formdata?.rejectionidar :  formdata?.rejectionid;
          }
          break;
        case "register":
          {
            cc = formdata.ccRegistration? formdata.ccRegistration.map((email) => email.displayValue):null
            templateID = data?.language ==='ar'?formdata?.registrationidar : formdata?.registrationid;
            subject = formdata?.registrationsubject;
            message = data?.language ==='ar'?formdata?.registrationmessagesubjectar : formdata?.registrationmessagesubject;
          }
          break;
        case "badgecollected":
          {
            subject = formdata?.badgecollectionsubject;
            templateID = formdata?.badgecollectedid;
          }
          break;
        case "badgecollection":
          {
            subject = formdata?.badgecollectedsubject;
            templateID = formdata?.badgecollectionid;
          }
          break;
      }

      let salutation = "";
      let respect = "";
      let englishTitle = data?.title
        ? data.title[0].toUpperCase() + data.title.slice(1) + "."
        : "";
      switch (data?.gender) {
        case "male":
          {
            respect = "المحترم";
          }
          break;
        case "female":
          {
            respect = "المحترمة";
          }
          break;
        default:
          "";
      }

      if (data?.language) {
        switch (data.title) {
          case "mr":
            {
              salutation =
                "السيد" +
                " " +
                data?.firstName +
                " " +
                data?.lastName +
                " " +
                "المحترم";
            }
            break;
          case "mrs":
            {
              salutation =
                "السيدة" +
                " " +
                data?.firstName +
                " " +
                data?.lastName +
                " " +
                "المحترمة";
            }
            break;
          case "ms":
            {
              salutation =
                "الآنسة" +
                " " +
                data?.firstName +
                " " +
                data?.lastName +
                " " +
                "المحترمة";
            }
            break;
          case "dr":
            {
              salutation =
                "الدكتور" +
                " " +
                data?.firstName +
                " " +
                data?.lastName +
                " " +
                respect;
            }
            break;
          case "prof":
            {
              salutation =
                "البروفيسور" +
                " " +
                data?.firstName +
                " " +
                data?.lastName +
                " " +
                respect;
            }
            break;

          case "hisExcellency":
            {
              salutation =
                "سعادة السيد" +
                " " +
                data?.firstName +
                " " +
                data?.lastName +
                " " +
                "المحترم";
            }
            break;
          case "herExcellency":
            {
              salutation =
                "سعادة السيدة" +
                " " +
                data?.firstName +
                " " +
                data?.lastName +
                " " +
                "المحترمة";
            }
            break;
          case "other":
            {
              salutation =
                data?.firstName + " " + data?.lastName + " " + respect;
            }
            break;
          default:
            "";
        }
      }

      switch (data.title) {
        case "other":
          {
            englishTitle = "Dear ";
          }
          break;
        case "hisExcellency":
          {
            englishTitle = "Your Excellency";
          }
          break;
        case "herExcellency":
          {
            englishTitle = "Your Excellency";
          }
          break;
        case "mr":
          {
            englishTitle =
              "Dear " + data.title[0].toUpperCase() + data.title.slice(1) + ".";
          }
          break;
        case "mrs":
          {
            englishTitle =
              "Dear " + data.title[0].toUpperCase() + data.title.slice(1) + ".";
          }
          break;
        case "ms":
          {
            englishTitle =
              "Dear " + data.title[0].toUpperCase() + data.title.slice(1) + ".";
          }
          break;
        case "dr":
          {
            englishTitle =
              "Dear " + data.title[0].toUpperCase() + data.title.slice(1) + ".";
          }
          break;
        case "prof":
          {
            englishTitle =
              "Dear " + data.title[0].toUpperCase() + data.title.slice(1) + ".";
          }
          break;
        default:
          "";
      }
      // alert(data?.url)
      data = {
        ...data,
        cc,
        uid:uid,
        code: data.code,
        parentURL: data?.url ? data?.url : "",
        FullName: data?.firstName + " " + data?.lastName,
        LOGO_URL: eventData?.image_url,
        FULLNAME: data?.firstName + " " + data?.lastName,
        TITLEEN: englishTitle?englishTitle:"",
        TITLE: salutation?salutation:"",
        FULLNAMEINENGLISH: data?.fullNameInEnglish?data?.fullNameInEnglish:"",
        subject: subject?subject:"",
        roomName: data?.roomName?data?.roomName:"",
        ReasonForRejection: data.reason ? data.reason : "",
      }

      if (data?.TITLEEN && data?.TITLEEN?.length>0 && data?.FULLNAME?.length>0 && data?.FULLNAME) {
        data = {
          ...data,
          COMBINED_NAME : `${data?.TITLEEN} ${data?.FULLNAME}`
        }
    }
      console.log(data)
      // formdata.isChild
     try {
      if(data?.phoneNumber?.e164Number){
        this.awsSnsService.sendSMS(data.phoneNumber.e164Number, this.replacePlaceholders(message, data))
     }
     } catch (error) {
      
     }
      const dataToSend = {
        ...data,
        cc,
        emailto: data.emailAddress,
        emailfrom: formdata?.emailfrom,
        subject: subject,
        templateKey: templateID,
        templateId: templateID,
        roomName: data.roomName,
        code: data.code,
        substitutions: {
          name: data?.firstName + " " + data?.lastName,
          email: "email",
        }
      }
      console.log(dataToSend)
     if(data?.emailAddress){
      await Axios.post("/email", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        ...dataToSend,
        data: {
         ...dataToSend,
        },
      })
        .then(async(e) => {
          console.log('Email sent successfully:', data.emailAddress);
         await this.updateSubmit(data.submitID,{emailData:dataToSend})
         .then(()=>{
          console.log(dataToSend)
          resolve(dataToSend);
         })
         .catch((e)=>{
          console.log('Email not sent successfully:', data);
         })
        
        })
        .catch((e) => {
         
          resolve(e);
        });
     }
     else{
      resolve(data);
     }
    
    });
  }
  
  replacePlaceholders(message: string, data: { [key: string]: string }): string {
    // Regular expression to match placeholders like {{firstName}}
    const placeholderRegex = /{{(.*?)}}/g;

    // Replace placeholders in the message with actual values
    const replacedMessage = message?.replace(placeholderRegex, (match, key) => {
      const value = data[key.trim()];
      return value !== undefined ? value : match;
    });

    return replacedMessage;
  }

  getSubmitByID(id) {
    return firebase.firestore().collection("/submits/").where('uid','==',id).get();
  }
  getSubmitByDocID(id) {
    return firebase.firestore().collection("/submits/").doc(id).get();
  }
  

  async deleteSubmit(item, formID) {
    firebase.firestore().collection("/submits/").doc(item.uid).set({deleted:true},{merge:true});
    await this.statusCounterUpdate(formID, "submits", -1);
    const room = item?.data?.personalInformation1?.selectTimeSlot;
    if (room) {
      const parts = room.selectedTime.split(":"); // Split the string at the colon
      const result = parts[0]; // Get the first part of the split string
      await this.roomsService.decrementRoom(room.roomID, result, room.selectedDate)
    }

    return await this.statusCounterUpdate(formID, item.status, -1);
  }

  aproveSubmit(id, formID) {
    let status = "approved";
    let data: any = {
      status: status,
    };
    return firebase
      .firestore()
      .collection("/submits/")
      .doc(id)
      .update({ ...data }, { merge: true })
      .then(async (response) => {
        // alert(JSON.stringify(response));
        // await this.statusCounterUpdate(formID, "approved", 1);
        // await this.statusCounterUpdate(formID, "pending", -1);
        const submit = response;
        return submit;
      });
  }

  completeSubmit(id, formID) {
    let status = "completed";
    let data: any = {
      status: status,
    };
    return firebase
      .firestore()
      .collection("/submits/")
      .doc(id)
      .update({ ...data }, { merge: true })
      .then(async (response) => {
        // alert(JSON.stringify(response));
        // await this.statusCounterUpdate(formID, "approved", 1);
        // await this.statusCounterUpdate(formID, "pending", -1);
        const submit = response;
        return submit;
      });
  }


  noShowSubmit(id, formID) {
    let status = "no show";
    let data: any = {
      status: status,
    };
    return firebase
      .firestore()
      .collection("/submits/")
      .doc(id)
      .update({ ...data }, { merge: true })
      .then(async (response) => {
        // alert(JSON.stringify(response));
        // await this.statusCounterUpdate(formID, "approved", 1);
        // await this.statusCounterUpdate(formID, "pending", -1);
        const submit = response;
        return submit;
      });
  }

  cancelSubmit(id, formID) {
    let status = "canceled";
    let data: any = {
      status: status,
    };
    return firebase
      .firestore()
      .collection("/submits/")
      .doc(id)
      .update({ ...data }, { merge: true })
      .then(async (response) => {
        // alert(JSON.stringify(response));
        // await this.statusCounterUpdate(formID, "approved", 1);
        // await this.statusCounterUpdate(formID, "pending", -1);
        const submit = response;
        return submit;
      });
  }

  


  statusCounterUpdate(id, type, incrementAmount) {
    const documentRef = firebase.firestore().collection("/forms/").doc(id);
    const increment = firebase.firestore.FieldValue.increment(incrementAmount);
    const varName = `submitsCounter.${type}`;
    return documentRef.update({
      [varName]: increment,
    });
  }
  rejectSubmit(id, reason, formID) {
    let status = "rejected";
    let data = { status: status, reason: reason };
    return firebase
      .firestore()
      .collection("/submits/")
      .doc(id)
      .set(data, { merge: true })
      .then(async (response: any) => {
        await this.statusCounterUpdate(formID, "rejected", 1);
        await this.statusCounterUpdate(formID, "pending", -1);
        const submit = response;
        return submit;
      });
  }
  getSubmitsByEvent() { }
  getSubmitsByCompanyID() { }

  statusCounterEventUpdate(id, type, incrementAmount) {
    const documentRef = firebase.firestore().collection("/events/").doc(id);
    const increment = firebase.firestore.FieldValue.increment(incrementAmount);
    const varName = `submitsCounter.${type}`;
    return documentRef.update({
      [varName]: increment,
    });
  }
  
  updateSubmit(id, data) {
    return firebase
      .firestore()
      .collection("/submits/")
      .doc(id)
      .update({ ...data }, { merge: true })
      .then(async(response: any) => {
        await this.statusCounterEventUpdate(data.eventID,'submits',1)
        if(data?.passportPictureURL){
         await this.mindeeService.checkPassportServerSide(id,data?.passportPictureURL)
        }
        if(data?.profilePictureURL){
        await this.mindeeService.checkProfileServerSide(id,data?.profilePictureURL)
        }
        const submit = response;
        return submit;
      })
      .catch((e)=>{
        console.log('Mindee error',{error:e})
        return
      });
  }
  updateSubmitWithoutScan(id, data) {
    return firebase
      .firestore()
      .collection("/submits/")
      .doc(id)
      .update({ ...data }, { merge: true })
      .then(async(response: any) => {
        const submit = response;
        return submit;
      })
      .catch((e)=>{console.log(e)});
  }
  updateSubmitByPassportPictureURL(url, data) {
    return firebase
      .firestore()
      .collection("/submits/")
      .where("passportPictureURL", "==", url)
      .where("status", "==", "pending")
      .get()
      .then((response: any) => {
        response.forEach((data) => {
          let form = data.data();
          form.status = "approved";
          this.updateSubmit(form.uid, form);
        });
      });
  }

    // Get Submit with its related Transportation, Accommodation, and Flight
    getSubmitWithDetails(submitId) {
      const submitRef =  firebase.firestore().collection('submits').doc(submitId);
  
      return submitRef.get()
        .then(submitDoc => {
          if (!submitDoc.exists) {
            console.error('No submit found!');
            return null;
          }
  
          const submitData = submitDoc.data();
          const detailsPromises = [];
  
          // Fetch Transportation if reference exists
          if (submitData.transportationRef) {
            detailsPromises.push(submitData.transportationRef.get().then(doc => ({
              type: 'transportation',
              data: doc.data()
            })));
          }
  
          // Fetch Accommodation if reference exists
          if (submitData.accommodationRef) {
            detailsPromises.push(submitData.accommodationRef.get().then(doc => ({
              type: 'accommodation',
              data: doc.data()
            })));
          }
  
          // Fetch Flight if reference exists
          if (submitData.flightRef) {
            detailsPromises.push(submitData.flightRef.get().then(doc => ({
              type: 'flight',
              data: doc.data()
            })));
          }
  
          return Promise.all(detailsPromises).then(details => {
            const result = { submit: submitData };
            details.forEach(detail => {
              result[detail.type] = detail.data;
            });
            return result;
          });
        })
        .catch(error => {
          console.error('Error fetching submit with details:', error);
          throw error;
        });
    }
  

  canRead(user: UserX): boolean {
    const allowed = ["owner", "admin", "user", "mofa"];
    return this.checkAuthorization(user, allowed);
  }

  canEdit(user: UserX): boolean {
    const allowed = ["owner", "admin", "mofa"];
    return this.checkAuthorization(user, allowed);
  }

  canDelete(user: UserX): boolean {
    const allowed = ["owner"];
    return this.checkAuthorization(user, allowed);
  }
  canCreate(user: UserX): boolean {
    const allowed = ["owner"];
    return this.checkAuthorization(user, allowed);
  }

  checkAuthorization(user: UserX, allowedRoles: string[]): boolean {
    if (!user) return false;
    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true;
      }
    }
    return false;
  }
}
