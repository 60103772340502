import { Component, Injector, OnInit } from "@angular/core";
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from "ngx-intl-tel-input";

import { RoomsService } from "src/app/core/services/rooms.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { firebase } from "src/app/fbInitialize";
import { FormService } from "src/app/core/services/form.service";
import { SubmitService } from "src/app/core/services/submits.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CategoriesService } from "src/app/core/services/categories.service";
import { CompanyEventsService } from "src/app/core/services/company-events.service";
import { flags, nationalities, titles } from "./data";
import moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/core/services/language.service";
import { registerCustomComponentEventRoomsPicker } from "src/app/formio/rating-wrapper.formio";


@Component({
  selector: 'app-pavilion-booking',
  templateUrl: './pavilion-booking.component.html',
  styleUrls: ['./pavilion-booking.component.scss']
})
export class PavilionBookingComponent implements OnInit {


  // Define the formSubmit group
  formSubmit: FormGroup;
  loading = false;
  selectedLanguage: string = 'English';
  languages : string[] = ['English', 'Arabic'];
  selectedHour: any;
   greaterThanZeroValidator = (control) => {
    if (control.value <= 0) {
      return { invalidValue: true };
    }
    return null;
  };
  // Define formSubmit controls for each field
  attendeesControl = this.fb.control(0, [Validators.min(1)]);
  titleControl = this.fb.control("", Validators.required);
  phoneControl = this.fb.control("");
  durationControl = this.fb.control("" ,Validators.required
  );
  otherTitleControl = this.fb.control("");
  firstNameControl = this.fb.control("", Validators.required);
  lastNameControl = this.fb.control("", Validators.required);
  nationalityControl = this.fb.control("", Validators.required);
  genderControl = this.fb.control("", Validators.required);
  additionalRequestsControl = this.fb.control("");
  dateList: string[] = [];
  // emailAddressControl = this.fb.control("", [
  //   Validators.required,
  //   Validators.email,
  // ]);
  phoneNumber:any
  formData: any;
  organizationControl = this.fb.control("", Validators.required);
  // jobTitleControl = this.fb.control("", Validators.required);
  roomControl = this.fb.control("", Validators.required);
  dateControl = this.fb.control("", Validators.required);
  timeControl = this.fb.control("", Validators.required);
  objectControl = this.fb.control("", Validators.required);

  // meetingTypeControl = this.fb.control("", Validators.required);
  // flagsNeededControl = this.fb.control("", Validators.required);
  // countryControl = this.fb.control("");

  durations = [
    { label: "30 min", arabicLabel: "٣٠ دقيقة", value: 30 },
    // { label: "35 min", arabicLabel: "٣٥ دقيقة", value: 35 },
    // { label: "40 min", arabicLabel: "٤٠ دقيقة", value: 40 },
    { label: "45 min", arabicLabel: "٤٥ دقيقة", value: 45 },
    // { label: "50 min", arabicLabel: "٥٠ دقيقة", value: 50 },
    // { label: "55 min", arabicLabel: "٥٥ دقيقة", value: 55 },
    { label: "60 min", arabicLabel: "٦٠ دقيقة", value: 60 },
  ];
  duration: any;
  submits = [];
  allLoaded = false;
  selectedDate: any;
  isArabic = false;
  firstFetch = true;

  

  constructor(
    private categoryService: CategoriesService,
    private router: Router,
    private roomsService: RoomsService,
    private fb: FormBuilder,
    private formService: FormService,
    private submitService: SubmitService,
    private eventService: CompanyEventsService,
    public languageService: LanguageService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    injector: Injector,
  ) {
    this.loading = true;
    if (this.router.url.includes('lang=ar')) {
      this.selectedLanguage = 'Arabic';
    } else {
      this.selectedLanguage = 'English';
  
    }
    // Subscribe to changes in the date formSubmit control
    this.formSubmit = this.fb.group({
      attendees: this.attendeesControl,
      title: this.titleControl,
      otherTitle: this.otherTitleControl,
      firstName: this.firstNameControl,
      lastName: this.lastNameControl,
      // nationality: this.nationalityControl,
      // gender: this.genderControl,
      // emailAddress: this.emailAddressControl,
      organization: this.organizationControl,
      // object: this.objectControl,
      // jobTitle: this.jobTitleControl,
      room: this.roomControl,
      date: this.dateControl,
      time: this.timeControl,
      additionalRequests:this.additionalRequestsControl,
      phoneNumber: this.phoneControl,
      duration: this.durationControl,
      // meetingType:this.meetingTypeControl,
      // flagsNeeded:this.flagsNeededControl,
      // selectedFlags:this.countryControl,
    });
    try {
    } catch (error) {
      
    }
    this.formSubmit.get("room").valueChanges.subscribe((room) => {
      if(!this.firstFetch){
        this.selectedRoom = this.findRoomByUid(room);
        if (this.firstFetch) {
          this.onRoomChange(
            this.selectedRoom
          );
  
          this.formSubmit.patchValue({
            date: this.minDate, // Replace with your desired value
          });
      }
    
        this.firstFetch = false;
      }
    });
  }
  onDateChange(event) {
    this.selectedRoom = null
    this.formSubmit.patchValue({
      room: "", // Replace with your desired value
      time: ""
    });
    this.selectedDate = event.target.value;
    this.onRoomChange(
      this.selectedRoom
    );
  }
  staticRooms = [];
  separateDialCode = true;
  searchCountryField = SearchCountryField;
  countryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  minDate: any;
  maxDate: any;
  preferredCountries: CountryISO[] = [
    CountryISO.Qatar, // Default preferred countries
    // Add more default preferred countries here
  ];
  selectedRoom: any;
  nationalities = nationalities
  flags = flags
  titles = titles;
  rooms = [];
  hours = [];
  eventID = "qEzjl5xc9Olt6TH3CKYc";
  formID = "zhXmL8apygDQnp4rLJ2Q";
  event: any;
  languageFromURL = 'en';

  ngOnInit(): void {
    // this.route.queryParams.subscribe(async (params) => {
    //   if (params["lang"]) this.languageFromURL = params["lang"];
    //   if(params["lang"]==='ar') this.isArabic = true
    // });
    this.loading = true;
    this.nationalities = this.nationalities.map(item => {
      return {
        label: item.label.charAt(0).toUpperCase() + item.label.slice(1).toLowerCase(),
        value: item.value, // Assuming there is a 'value' property to maintain
        arabicLabel: item.arabicLabel
      };
    });
  
    this.nationalities = this.nationalities.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

    this.populateDurations();
    this.getRooms(this.eventID);
    const formID = this.formID;
    this.eventService.getEventByID(this.eventID).then((data) => {
      this.event = data.data();
    });
    const formInstance = this.formService.getFormByID(formID);
    formInstance.then((data) => {
      this.formData = data.data();
    });

    this.route.params.subscribe((params) => {
      const lang = params["lang"]; // Access the 'lang' parameter from the URL
      if (this.router.url.includes('lang=ar')) {
        this.setLanguage("ar");
        this.isArabic = true;
        // Perform actions specific to the 'ar' language
        console.log('The URL contains "ar"');
      } else {
        this.isArabic = false;
        this.setLanguage("en");
        // Perform actions for other languages
        console.log('The URL does not contain "ar"');
      }
    });
  }

  populateDurations() {
    this.duration = { label: "30 min", arabicLabel: "٣٠ دقيقة", value: 30 }
  }

  setLanguage(lang: string) {
    this.languageService.setLanguage(lang);
  }

  countryChange(e) {}

  async getRooms(eventID) {
    try {
      const roomsArray = await this.roomsService.getRoomsCollectionByEvent(
        eventID
      );

      // this.selectedDate = this.minDate;
      this.rooms = [];
      this.staticRooms = [];
      roomsArray.docs
        .map((doc) => {
          const roomData = doc.data();
          if (roomData?.deleted != true) {
            this.rooms.push(roomData);
        
            this.staticRooms.push(roomData);
          }
          // Assuming your room data contains a 'roomName' property
        })
        .reverse();

      if (this.rooms.length > 0) {
        // this.formSubmit.patchValue({
        //   room: this.rooms[0].uid, // Replace with your desired value
        // });
 
        // this.onRoomChange(
        //   this.rooms[0]
        // );

        this.minDate = this.formatDate(this.rooms[0]?.date[0].seconds); // Start date timestamp
  
        const selectedDate = moment.unix(this.rooms[0]?.date[0].seconds).add(1, "day");
    
        // Convert the moment object back to seconds
        const selectedDateInSeconds = selectedDate.unix();
    
        this.maxDate = this.formatDate(this.rooms[0]?.date[1].seconds); // End date timestamp
        
        // Get the current date as a formatted string
        const currentDate = this.formatDate(Date.now() / 1000); // Current date timestamp
    
        // Set minDate to currentDate if it's in the past
        this.minDate = this.minDate < currentDate ? currentDate : this.minDate;
    

        this.selectedDate = this.minDate;
        this.formSubmit.patchValue({
      time: "",
          date: this.selectedDate, 
        });
        this.populateDateList()
      }
      this.loading = false
    } catch (error) {
      this.rooms = []; // Set an empty array in case of an error
    }
  }

  getAMPM(time: string): string {
    const [hours] = time.split(":").map(Number);
    return hours >= 12 ? "pm" : "am";
  }

  getAMPMAR(time: string): string {
    const [hours] = time.split(":").map(Number);
    return hours >= 12 ? "م" : "ص";
  }

  findRoomByUid(uid: string): any {
    return this.rooms.find((room) => room.uid === uid);
  }

  formatDate(timestamp: number): string {
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  async fetchAllSubmits(id: any): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.submitService
        .getSubmitsByRoomID(id)
        .then((resp) => {
          let array = [];
          resp?.forEach((data, index) => {
            if (!data.deleted) {
              array.push(data);
            }
          });

          this.submits = array;

          resolve(this.submits); // Resolve the Promise with the fetched data
        })
        .catch((error) => {
          // Handle errors here

          reject(error); // Reject the Promise with the error
        });
    });
  }

  private populateDateList(): void {
    this.dateList = []
    let minDate = new Date(this.minDate);
    let maxDate = new Date(this.maxDate);
    let currentDate = new Date(minDate.getTime());

    while (currentDate <= maxDate) {
      this.dateList.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  async onRoomChange( room) {
  if(room){
      // Retrieve the selected value from the event object
      this.selectedRoom = room;
      // alert(event.target.value)
      // Calculate the minDate and maxDate based on the selected room's date values
      this.minDate = this.formatDate(room?.date[0].seconds); // Start date timestamp
  
      const selectedDate = moment.unix(room?.date[0].seconds).add(1, "day");
  
      // Convert the moment object back to seconds
      const selectedDateInSeconds = selectedDate.unix();
  
      this.maxDate = this.formatDate(room?.date[1].seconds); // End date timestamp
      
      // Get the current date as a formatted string
      const currentDate = this.formatDate(Date.now() / 1000); // Current date timestamp
  
      // Set minDate to currentDate if it's in the past
      this.minDate = this.minDate < currentDate ? currentDate : this.minDate;
      this.populateDateList()
      if (!this.selectedDate) {
        this.selectedDate = this.minDate;
      }
      // this.hours = [];
      // hours.forEach((hour) => {
      //   if (this.isDateHourFull(hour, this.minDate)) this.hours.push(hour);
      // });
      let allRoom = [];
      for (let room of this.staticRooms) {
        if (room.uid) {
          let roomHours = await this.selectableHours(
            room.startTime,
            room.endTime,
            room
          );
          let finalHours = [];
          roomHours.forEach((hour) => {
            if (this.isDateHourFull(hour, this.minDate)) finalHours.push(hour);
          });
          room.hours = finalHours;
  
          let newRoom = {
            ...room,
            hours: finalHours,
          };
  
          allRoom.push(newRoom);
        }
      }
      this.rooms = allRoom;
      this.onAttendeesChange({
        target:{
          value:this.formSubmit.get('attendees').value
        }
      },false
      )

  }
  }

  async selectableHours(
    startTime: string,
    endTime: string,
    room
  ): Promise<string[]> {
    this.submits = [];
    this.loading = true;
    const data = await this.fetchAllSubmits(room.uid);
    const bookings = data;
    const startInteger = this.timeToMinutes(startTime);
    const endInteger = this.timeToMinutes(endTime);
    const hours = [];
    let durationInMinutes = this.duration.value + 10;
    console.log({
      room: room.uid,
      selectedDate: this.selectedDate,
      bookings: bookings,
      startTime,
      endTime,
    });
    for (let i = startInteger; i <= endInteger - durationInMinutes; i += 5) {
      const time = this.minutesToTime(i);
      let isAvailable = true;

      for (const booking of bookings) {
        const bookingStartTime = new Date(
          booking.data?.personalInformation1?.bookingStartTime
        ).getTime();
        const bookingEndTime = new Date(
          booking.data.personalInformation1?.realBookingEndTime
        ).getTime();
        const currentTime = new Date(
          this.selectedDate + "T" + time + ":00Z"
        ).getTime();
        const bookingEndTimeWithBuffer = bookingEndTime; // Buffer for booked duration
        if (
          (currentTime >= bookingStartTime && currentTime < bookingEndTimeWithBuffer) ||
          this.checkTimeBetween(currentTime + durationInMinutes * 60000, bookingStartTime, bookingEndTime) ||
          (currentTime < bookingStartTime && currentTime + durationInMinutes * 60000 > bookingStartTime)
        ) {
          isAvailable = false;
          break;
        }
      }
      const arabicTime = this.translateToArabic(time); // Assuming you have a function to translate to Arabic
      if (isAvailable) hours.push({ value: time, isAvailable, arabicTime });
    }

    this.loading = false;
    return Promise.resolve(hours);
  }

  translateToArabic(time: string): string {
    // Assuming time is in the format 'HH:MM'
    const [hours, minutes] = time.split(":");
    const arabicHours = this.convertNumberToArabic(parseInt(hours));
    const arabicMinutes = this.convertNumberToArabic(parseInt(minutes));
    const paddedHours = (hours.length === 1 ? "٠" + arabicHours : arabicHours).padStart(2, '٠');
    const paddedMinutes = (minutes.length === 1 ? "٠" + arabicMinutes : arabicMinutes).padStart(2, '٠');
    return paddedHours + ":" + paddedMinutes;
  }
  

  convertNumberToArabic(number: number): string {
    // Define your own logic for converting numbers to Arabic
    // This is a simplified example
    if(this.isArabic){
      const arabicNumbers = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
      return number
        .toString()
        .split("")
        .map((digit) => arabicNumbers[parseInt(digit)])
        .join("");
    }
    return number.toString()
  }

  checkTimeBetween(
    currentTime: number,
    startTime: number,
    endTime: number
  ): boolean {
    return currentTime >= startTime && currentTime <= endTime;
  }

  // selectableHours(startTime: string, endTime: string): string[] {
  //   const startInteger = this.timeToMinutes(startTime);
  //   const endInteger = this.timeToMinutes(endTime);
  //   const hours: string[] = [];

  //   for (let i = startInteger; i <= endInteger; i += 5) {
  //     hours.push(this.minutesToTime(i));
  //   }
  //
  //
  //   return hours;

  // }

  // selectableHours(startTime: any, endTime: any): number[] {
  //   const hours = [];
  //   for (
  //     let i = this.timeToInteger(startTime);
  //     i <= this.timeToInteger(endTime);
  //     i++
  //   ) {
  //     hours.push(i.toString());
  //   }
  //
  //
  //   return hours;
  // }

  timeToMinutes(time: string): number {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  }

  minutesToTime(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${this.padZero(hours)}:${this.padZero(mins)}`;
  }

  padZero(num: number): string {
    return num < 10 ? `0${num}` : num.toString();
  }

  timeToInteger(timeString) {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours;
  }
  integerToTime(hours: number): string {
    // Ensure the hours are within a valid range (0-23)
    hours = Math.min(23, Math.max(0, hours));

    // Format the hours as two digits
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;

    // Return the formatted time string with minutes set to "00"
    return `${formattedHours}:00`;
  }

  toggleOtherTitleField() {
    // Determine whether to show the "Other Title" field based on the selected value
    const selectedTitle = this.formSubmit.get("title").value;
    if (selectedTitle === "other") {
      this.formSubmit.get("otherTitle").enable(); // Show the field
    } else {
      this.formSubmit.get("otherTitle").disable(); // Hide the field
    }
  }

  showOtherTitleField() {
    // Check if the "Other Title" field should be shown
    return this.formSubmit.get("title").value === "other";
  }

  isDateHourFull(hour, date): boolean {
    const data = this.selectedRoom;
    const key = `${date}:${hour}`;
    //
    if (data[key] !== undefined && data.capacity !== undefined) {
      //
      return data[key] < data.capacity;
    }
    //
    //
    //
    //
    return true;
  }
  async onSubmit() {
    console.log("submitting ... ");
    if (this.formSubmit.valid) {
      // Form is valid, proceed with submission
      const formData = this.formSubmit.value;
      try {
        this.loading = true;
        const db = firebase.firestore();
        const ref = db.collection("submits").doc();
        const id = ref.id;

        const room = this.findRoomByUid(formData.room);
        const date = formData.date; // Assuming the format is YYYY-MM-DD
        const startTime = formData.time.value; // Assuming the format is HH:mm
        const duration = parseInt(formData.duration); // Assuming the duration is in minutes
        const cleaningTime = 10; // Assuming the cleaning time is in minutes

        // Convert date and time into a JavaScript Date object
        const [hours, minutes] = startTime.split(":").map(Number);
        const [year, month, day] = date.split("-").map(Number);
        const bookingStartTime = new Date(
          Date.UTC(year, month - 1, day, hours, minutes)
        );
        const categoryRef = await this.categoryService.getCategoriesByID(
          "y94lkRpe2f8UEpFH8UlV"
        );
        let category = categoryRef.data();
        // Calculate the end time based on the start time and duration
        const bookingEndTime = new Date(
          bookingStartTime.getTime() + duration * 60000
        ); // Duration in milliseconds

        const realBookingEndTime = new Date(
          bookingStartTime.getTime() + (duration + cleaningTime) * 60000
        ); // Duration in milliseconds
        const submitNumber = this.event?.submitsCounter?.submits | 0
        
        const data = {
          categoryID: category?.uid,
          data: {
            code: `${this.event.code}-${this.numberToFourDigits(submitNumber + 1)}`,
            category:category?.name,
            categoryID: category?.uid,
            categoryClass:category?.categoryClass?category?.categoryClass:'',
            personalInformation1: {
              ...formData,
              bookingStartTime: bookingStartTime.toUTCString(),
              bookingEndTime: bookingEndTime.toUTCString(),
              realBookingEndTime: realBookingEndTime.toUTCString(),
              cleaningTime: cleaningTime,
              roomName: this.selectedRoom?.roomName,
              room: this.selectedRoom?.uid,
              code: `${this.event.code}-${this.numberToFourDigits(submitNumber + 1)}`,
            },
          },
          eventID: this.eventID,
          formID: this.formID,
          uid: id,
        };
        console.log(data)
        const hasCapacity = await this.roomsService.checkCapacity(
          this.selectedRoom.uid,
          this.timeToInteger(formData.time.value),
          formData.date
        );
        if (hasCapacity) {
          await this.formService.submitForm(data);
          await this.roomsService.incrementRoom(
            this.selectedRoom.uid,
            this.timeToInteger(formData.time.value),
            formData.date
          );

      

          const emailData = {
            category:category?.name,
            categoryClass:category?.categoryClass?category?.categoryClass:'',
            submitID: id,
            code: `${this.event.code}-${this.numberToFourDigits(submitNumber + 1)}`,
           ... data.data,
          ...formData,
          ...{
            selectedTime:formData?.time?.value
          },
          ...formData?.time,
          ...formData.phoneNumber,
          url:
          "https://diggri.com/thankyou/" +
          category?.tyPage?.uid +
          "/" +
          id,
           roomName: this.selectedRoom.roomName
          }
          this.event
          category ={
            ...this.event,
            ...category,
          }
          // Send confirmation email
         let emailResponse =  await this.submitService.sendEmail(
            emailData,
            "register",
            category,
            category,id
          );
          console.log(emailResponse)
          this.loading = false;

          if(category?.tyPage?.uid){
            this.router.navigate(["/thankyou/"+category.tyPage.uid+'/'+id])
          }
          else
          this.router.navigate([
            "/thankyou",
            {
              typ1: category?.typ1 ? category?.typ1 : "Registration Successful",
              typ2: category?.typ2
                ? category?.typ2
                : "Thank you, your registration has been successful. Our team will contact you within 24-48 hours.",
            },
          ]);
        } else {
          alert("full");
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    } else {
      // Form is invalid, show error messages

      this.formSubmit.markAllAsTouched();

      // Loop through formSubmit controls and log error messages
      Object.keys(this.formSubmit.controls).forEach((controlName) => {
        const control = this.formSubmit.get(controlName);
   
        console.log(control.errors);
        if (control.invalid) {
          console.log(controlName);
          const errors = control.errors;
          Object.keys(errors).forEach((errorName) => {
            console.log(controlName);
            console.log(errorName);
          });
        }
      });
    }
  }

  onAttendeesChange(event: any,changeSelectedRoom) {

      let selectedAttendees = 0; // Extract the selected number of attendees from the event
      if( event?.target?.value ){
        selectedAttendees = event?.target?.value 
      }
      else{
        selectedAttendees =  this.formSubmit.get('attendees').value

      }
    
      if(changeSelectedRoom){
        this.selectedRoom = null
        this.formSubmit.patchValue({
          room: "", // Replace with your desired value
          time: ""
        });
      }
     
      // Assuming you have an array of rooms with a 'capacity' property
      const availableRooms = this.staticRooms.filter(
        (room) => room.capacity >= selectedAttendees
      );
      if (this.rooms != this.staticRooms) {
        this.rooms = availableRooms;
      }
  

   
    // You can update the available rooms list in your form or do other necessary operations
  }

  selectHour(hour: string, room: any) {
    this.formSubmit.patchValue({
      room: room, // Replace selectedRoomValue with the value you want to set
      time: hour,
    });
    this.selectedRoom = room;
    // Do something with the selected hour, such as assigning it to a variable
    this.selectedHour = hour;
    // You can perform other actions here based on the selected hour
  }

  formatDateString(dateStr) {
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
  }

  onDurationChange(event) {
    const duration = event
    this.selectedRoom = null
    this.formSubmit.patchValue({
      room: "", // Replace with your desired value
      time: ""
    });
    if(duration){
      const selectedDuration = this.durations.find((duration) => {
        return duration.value === parseInt(event, 10);
      });
      console.log('duration')
      console.log(selectedDuration)
      console.log(duration)
      this.duration = selectedDuration;
  
      this.onRoomChange(
        this.selectedRoom
      );
    }
    else{
      this.duration = { label: "30 min", value: 30 };
      this.onRoomChange(
        this.selectedRoom
      );
    }
   
  }
  numberToFourDigits(number) {
    return number.toString().padStart(4, '0');
  }

  updateLanguage(event) {
    this.selectedLanguage = event.target.value
    // Check if the user selected Arabic
    if (this.selectedLanguage === 'Arabic') {
        // If the current URL doesn't contain 'lang=ar', navigate to the same URL with 'lang=ar' added
        if (!this.router.url.includes('lang=ar')) {
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: { lang: 'ar' },
                queryParamsHandling: 'merge'
            });
            const newUrl = this.router.url+ '?lang=ar'; // Remove the lang=ar parameter
            window.location.href = newUrl; // Reload the page with the modified URL
        }
    } else {
        // If the user selects English, navigate to the same URL without 'lang=ar' and reload the page
        if (this.router.url.includes('lang=ar')) {
        
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: { lang: null },
                queryParamsHandling: 'merge'
            });
            const newUrl = this.router.url.split('?lang=ar')[0]; // Remove the lang=ar parameter
            window.location.href = newUrl; // Reload the page with the modified URL
        }
    }
}

phoneChange(e) {
  this.phoneNumber = e;
    this.formSubmit.patchValue({
      phoneNumber: e,
    });
}

}
