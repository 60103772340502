import {
  Component,
  ElementRef,
  Inject,
  Injector,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CompanyEventsService } from "src/app/core/services/company-events.service";
import { EventService } from "src/app/core/services/event.service";
import { FormService } from "src/app/core/services/form.service";
import { SubmitService } from "src/app/core/services/submits.service";
import { firebase } from "src/app/fbInitialize";
import type {} from "css-font-loading-module";
import { CategoriesService } from "src/app/core/services/categories.service";
import AWS from "aws-sdk";
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from "ngx-intl-tel-input";
import {
  registerCustomComponent,
  registerCustomComponentCropImage,
  registerCustomComponentEventRoomsPicker,
  registerCustomComponentTimePicker,
} from "src/app/formio/rating-wrapper.formio";
import { TranslateService } from "@ngx-translate/core";
import { TranslationsService } from "src/app/core/services/translations.service";
import { TermsAndConditionsService } from "src/app/core/services/terms-and-conditions.service";

import * as pdf from "html-pdf-node";
import * as fs from "fs";

import html2canvas from "html2canvas";
import * as html2pdf from "html2pdf.js";
import domToImage from "dom-to-image";
import jsPDF, { jsPDFOptions } from "jspdf";
import moment from "moment";
import values from "../form/countries.translations";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-terms-and-conditions",
  templateUrl: "./terms-and-conditions.component.html",
  styleUrls: ["./terms-and-conditions.component.scss"],
})
export class TermsAndConditionsComponent implements OnInit {
  @ViewChild("pdfContent", { static: false }) public pdfContent: ElementRef;

  separateDialCode = true;
  searchCountryField = SearchCountryField;
  countryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  public editFormID: any;
  // @ViewChild("formio") formio: any;
  breadCrumbItems: Array<{}>;
  form: any;
  submitted = false;
  error = "";
  successmsg = false;
  inputs: Array<{}>;
  formx: any;
  profile: any;
  passport: any;
  loading = true;
  oneoftwo: true;
  event: any;
  font: any;
  language = "english";
  lang: any;
  languageFromURL: any;
  mainSuper: any;
  hasCategory = false;
  category: any;
  stylesObj: any;
  submission: any;
  isClosed = false;
  categoryData: any = {
    disabled: false,
  };
  usedLanguage: any;
  data: any;
  submitID: any;
  submit: any;
  countryNames: any = {}; // Store translated country names
  templateType: string = "terms-and-conditions";

  constructor(
    injector: Injector,
    private router: Router,
    private termsAndConditionsService: TermsAndConditionsService,
    private submitService: SubmitService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService,
    private translationService: TranslationsService,
  ) {
    try {
      registerCustomComponentEventRoomsPicker(injector);
    } catch (error) {}
    this.lang = {};
  }

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(params => {
      // const lang = params.lang; // Extract the lang parameter here
      console.log({params})
      if (params["lang"]) this.languageFromURL = params["lang"];
        console.log({lang:this.languageFromURL})
      // this.languageFromURL = lang;
    });

    this.stylesObj = { "font-family": "FontAwesome", src: this.font };

    const routeParams = this.router.routerState.snapshot;
    const length = routeParams.url.split("/").length;
    this.editFormID =
      length === 4
        ? routeParams.url.split("/")[length - 2]
        : routeParams.url.split("/")[length - 1];

    if (routeParams.url.includes("terms-and-conditions")) {
      this.templateType = "terms-and-conditions";
    } else {
      if (
        routeParams.url.includes("thankyou") ||
        routeParams.url.includes("thank-you-pages")
      ) {
        this.templateType = "thank-you-pages";
        this.route.params.subscribe((params) => {
          this.submitID = params?.submitID; // Extract the lang parameter here
          if (this.submitID) {
            this.submitService.getSubmitByID(this.submitID).then((res) => {
              res.docs.forEach((res) => {
                this.submit = res.data();
              });
            });
          }
        });
      } else {
        if (routeParams.url.includes("email-reject-page")) {
          this.templateType = "email-reject-page";
          this.route.params.subscribe((params) => {
            this.submitID = params?.submitID; // Extract the lang parameter here
            if (this.submitID) {
              this.submitService.getSubmitByID(this.submitID).then((res) => {
                res.docs.forEach((res) => {
                  this.submit = res.data();
                });
                if (this.templateType === "email-reject-page") {
                  this.submitService.updateSubmit(this.submit.uid, {
                    data: {
                      ...this.submit.data,
                      tracking: {
                        didSubmit:false,
                        rsvp:false,
                        rejectedInvite: true
                      },
                    },
                  });
                }
              });
            }
          });
        }
      }
    }
    this.breadCrumbItems = [
      { label: "Admin" },
      { label: "Users" },
      { label: "Edit User", active: true },
    ];

    let array = [];
    try {
      const res: any = await this.termsAndConditionsService
        .getTermsAndConditionsByID(this.templateType, this.editFormID)
        .catch((error) => {
          this.loading = false;
          console.log({error})
        });
      this.formx = res.data();

      let translationsArray = await this.translationService
      .getTranslationsCollection()
      .get()
      .then((data) => {
        return data.docs.map((doc) => doc.data());
      })
      .catch((error) => {
        return [];
      });

    translationsArray = [...translationsArray, ...values];
    if (this.languageFromURL === "ar") {
      //
      //
      this.formx.inputs = this.translateLabels(
        this.formx.inputs,
        translationsArray
      );
      // Apply RTL class to Formio

      const formioElement = document.querySelector(".formio");
      if (formioElement) {
        formioElement.classList.add("rtl-formio padding-right-5");
      }
      this.language = "lt";
      this.lang = {
        language: "lt",
        i18n: {
          lt: {
            "is required": "مطلوب",
            "is required ": "مطلوب",
            browse: "تصفح",
            "Enter Phone no": "أدخل رقم الهاتف",
            "Type to search": "اكتب للبحث",
            or: "أو",
            "Drop files to attach,": "قم بإسقاط الملفات لإرفاقها ",
            // Add other translations
          },
        },
      };
      // Load translations here
      this.translateService
        .get("countryCodes")
        .subscribe((translations: any) => {
          this.countryNames = translations;
        });

      // Check if the language is 'ar'

      if (formioElement) {
        formioElement.classList.add("rtl-formio p-2");
      }
      this.loading = false;
      // Switch to the Arabic language
      this.translate.use("ar");
    }
    if (this.languageFromURL === "fr") {
      //
      //
      this.formx.inputs = this.translateLabels(
        this.formx.inputs,
        translationsArray
      );
      // Apply RTL class to Formio

      const formioElement = document.querySelector(".formio");
     
      this.language = "lt";
      this.lang = {
        language: "lt",
        i18n: {
          lt: {
            "is required": "est requis",
            "is required ": "est requis",
            browse: "Parcourir",
            "Enter Phone no": "Entrez le numéro de téléphone",
            "Type to search": "Tapez pour rechercher",
            or: "ou",
            "Drop files to attach,": "Déposez les fichiers à joindre,",
            // Add other translations
          },
        },
      };
      
      // Load translations here
      this.translateService
        .get("countryCodes")
        .subscribe((translations: any) => {
          this.countryNames = translations;
        });

      // Check if the language is 'ar'
      this.loading = false;
      // Switch to the Arabic language
      this.translate.use("fr");
    } 

      this.formx?.eventsIDs.forEach((element) => {
        array.push(element.uid);
      });

    try {
      if (this.submit?.data) {
        this.formx.inputs.forEach((element) => {
          if (element.html)
          {
            try {
              element.html = this.replacePlaceholders(
                element.html,
                this.submit?.emailData
              );
            } catch (error) {
              element.html = this.replacePlaceholders(
                element.html,
                this.submit?.data
              );
            }
          
          }
        });
      } else {
        this.formx.inputs.forEach((element) => {
          element.html = this.replacePlaceholders(element.html, {});
        });
      }
    } catch (error) {
      console.log({error})
    }

      // Check if the language is 'ar'

      if (this.formx?.inputs) {
        console.log('Inputs ? : ', this.formx.inputs);
        
        this.form = {
          components: this.formx.inputs
        };
      
        // Ensure submission is properly structured
        this.submission = {
          data: {
            usedLanguageField: this.submit?.data.usedLanguage || 'eng'
          }
        };
      
        console.log('Form Config:', this.form);
        console.log('Submission Data:', this.submission);
      }
      this.loadDynamicStyles(this.formx?.styles);

      // if (this.formx?.inputs) {

      //   this.form = {
      //     components: this.formx.inputs,
      //   };
      // }
      // this.form = {
      //   components: this.formx.inputs,
      // };
      console.log(this.formx);
      this.loading = false;
      this.translateService
        .get("countryCodes")
        .subscribe((translations: any) => {
          this.countryNames = translations;
        });
    } catch (error) {
      console.log({error})
      this.loading = false;
      // console.error("Error fetching form data:", error);
    }
  }

  private loadDynamicStyles(cssString: string) {
    const styleElement = this.renderer.createElement("style");
    this.renderer.setProperty(styleElement, "textContent", cssString); // Use textContent for security over innerHTML
    this.renderer.appendChild(this.document.head, styleElement);
  }

  replacePlaceholders(
    message: string,
    data: { [key: string]: string }
  ): string {
    // Regular expression to match placeholders like {{firstName}}
    const placeholderRegex = /{{(.*?)}}/g;

    // Replace placeholders in the message with actual values
    const replacedMessage = message?.replace(placeholderRegex, (match, key) => {
      const value = data[key.trim()] ? data[key.trim()] : "NaN";
      return value !== undefined ? value : match;
    });

    return replacedMessage;
  }

  translateLabels(components: any[], translations: any[]) {
    // let language = lang
    // if(!lang){
    //   language='en'
    // }
    components.forEach((component: any) => {
      if (component.label) {
        const matchingTranslation = translations.find(
          (translation) => translation.name === component.label
        );
        if (matchingTranslation) {
          component.label = matchingTranslation.ar; // Replace with the translated label
        }
      }
      if (component.placeholder) {
        const matchingTranslation = translations.find(
          (translation) => translation.name === component.placeholder
        );
        if (matchingTranslation) {
          component.placeholder = matchingTranslation.ar; // Replace with the translated placeholder
        }
      }
      // Translate options if applicable
      if (component.data && component.data.values) {
        for (const option of component.data.values) {
          const matchingTranslation = translations.find(
            (translation) => translation.name === option.label
          );
          if (matchingTranslation) {
            option.label = matchingTranslation.ar; // Replace with the translated option label
          }
        }
      }

      // Recursively traverse nested components
      if (component.components) {
        this.translateLabels(component.components, translations);
      }
      if (component.columns) {
        component.columns.forEach((column: any) => {
          if (column.components) {
            this.translateLabels(column.components, translations);
          }
        });
      }
    });
  }

  generatePDF() {
    const width = this.pdfContent.nativeElement.clientWidth;
    const height = this.pdfContent.nativeElement.clientHeight + 40;
    let orientation: any = "";
    let imageUnit: any = "pt";
    if (width > height) {
      orientation = "l";
    } else {
      orientation = "p";
    }
    domToImage
      .toPng(this.pdfContent.nativeElement, {
        width: width,
        height: height,
      })
      .then((result) => {
        let jsPdfOptions: jsPDFOptions = {
          unit: imageUnit,
          orientation: orientation,
          format: [width + 50, height + 220],
        };
        const pdf = new jsPDF(jsPdfOptions);

        pdf.addImage(result, "PNG", 25, 185, width, height);
        pdf.save("file_name" + ".pdf");
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
