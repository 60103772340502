import { Component, Injector, OnInit } from "@angular/core";
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from "ngx-intl-tel-input";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
import { RoomsService } from "src/app/core/services/rooms.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { firebase } from "src/app/fbInitialize";
import { FormService } from "src/app/core/services/form.service";
import { SubmitService } from "src/app/core/services/submits.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CategoriesService } from "src/app/core/services/categories.service";
import { CompanyEventsService } from "src/app/core/services/company-events.service";
import { flags, nationalities, titles } from "./data";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/core/services/language.service";

@Component({
  selector: "app-booking-rooms",
  templateUrl: "./booking-rooms.component.html",
  styleUrls: ["./booking-rooms.component.scss"],
})
export class BookingRoomsComponent implements OnInit {
  // Define the formSubmit group
  formSubmit: FormGroup;
  loading = false;
  selectedLanguage: string = "English";
  languages: string[] = ["English", "Arabic"];
  attendeesOptions = Array.from({ length: 16 }, (_, i) => i + 1);
  selectedHour: any;
  CountryISO = CountryISO;
  greaterThanZeroValidator = (control) => {
    if (control.value <= 0) {
      return { invalidValue: true };
    }
    return null;
  };
  // Define formSubmit controls for each field
  attendeesControl = this.fb.control(1, [Validators.min(1)]);
  objectControl = this.fb.control("", Validators.required);
  titleControl = this.fb.control("");
  phoneControl = this.fb.control("", [Validators.required]);
  durationControl = this.fb.control("", Validators.required);
  otherTitleControl = this.fb.control("");
  firstNameControl = this.fb.control("");
  lastNameControl = this.fb.control("");
  additionalRequestsControl = this.fb.control("");
  bsConfig: Partial<BsDatepickerConfig>;
  emailAddressControl = this.fb.control("", [
    Validators.required,
    Validators.email,
  ]);
  date: any;
  phoneNumber: any;
  formData: any;
  organizationControl = this.fb.control("");
  roomControl = this.fb.control("", Validators.required);
  dateControl = this.fb.control("", Validators.required);
  timeControl = this.fb.control("", Validators.required);
  responsiblePartyControl = this.fb.control("");
  attendeesListControl = this.fb.control("");
  countryControl = this.fb.control("");

  durations = [
    { label: "25 min", arabicLabel: "٢٥ دقيقة", value: 25 },
    { label: "30 min", arabicLabel: "٣٠ دقيقة", value: 30 },
    { label: "45 min", arabicLabel: "٤٥ دقيقة", value: 45 },
    { label: "60 min", arabicLabel: "٦٠ دقيقة", value: 60 }
  ];
  
  duration: any;
  submits = [];
  allLoaded = false;
  selectedDate: any;
  isArabic = false;
  firstFetch = true;
  autoApprove = false;
  dateList: string[] = [];

  cleaningTime = 10; // Assuming the cleaning time is in minutes
  constructor(
    private categoryService: CategoriesService,
    private router: Router,
    private roomsService: RoomsService,
    private fb: FormBuilder,
    private formService: FormService,
    private submitService: SubmitService,
    private eventService: CompanyEventsService,
    public languageService: LanguageService,
    public translate: TranslateService,
    private route: ActivatedRoute,
    injector: Injector
  ) {
    this.loading = true;
    if (!this.router.url.includes("websummit-2025")) {
      this.autoApprove = true
    } 
    if (this.router.url.includes("lang=ar")) {
      this.selectedLanguage = "Arabic";
    } else {
      this.selectedLanguage = "English";
    }
    // Subscribe to changes in the date formSubmit control
    this.formSubmit = this.fb.group({
      attendees: this.attendeesControl,
      title: this.titleControl,
      otherTitle: this.otherTitleControl,
      firstName: this.firstNameControl,
      lastName: this.lastNameControl,
      emailAddress: this.emailAddressControl,
      organization: this.organizationControl,
      room: this.roomControl,
      date: this.dateControl,
      time: this.timeControl,
      responsibleParty: this.responsiblePartyControl,
      attendeesList: this.attendeesListControl,
      meetingTitle: this.objectControl,
      additionalRequests: this.additionalRequestsControl,
      phoneNumber: this.phoneControl,
      duration: this.durationControl,
      selectedFlags: this.countryControl,
    });
    try {
    } catch (error) {}
    this.formSubmit.get("room").valueChanges.subscribe((room) => {
      if (!this.firstFetch) {
        this.selectedRoom = this.findRoomByUid(room);
        if (this.firstFetch) {
          this.onRoomChange(this.selectedRoom);

          this.formSubmit.patchValue({
            date: this.minDate, // Replace with your desired value
          });
        }

        this.firstFetch = false;
      }
    });
  }
  onDateChange(event) {
    this.selectedRoom = null;
    this.formSubmit.patchValue({
      room: "",
    });
    this.selectedDate = event;
    // this.onRoomChange(this.selectedRoom);
  }

  formatDateToString(dateString: string): string {
    let dateObject = new Date(dateString);
    return dateObject.toISOString().split("T")[0];
  }

  staticRooms = [];
  separateDialCode = true;
  searchCountryField = SearchCountryField;
  countryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  minDate: any;
  maxDate: any;
  preferredCountries: CountryISO[] = [
    CountryISO.Qatar, // Default preferred countries
    // Add more default preferred countries here
  ];
  selectedRoom: any;
  nationalities = nationalities;
  flags = flags;
  titles = titles;
  rooms = [];
  hours = [];
  eventID = "wtV3bYAOWxCzZ8rusaEv";
  formID = "RBnsfd8Y6cFEsXV8NTEc";
  event: any;
  languageFromURL = "en";

  ngOnInit(): void {
    // this.route.queryParams.subscribe(async (params) => {
    //   if (params["lang"]) this.languageFromURL = params["lang"];
    //   if(params["lang"]==='ar') this.isArabic = true
    // });
    this.loading = true;
    this.nationalities = this.nationalities.map((item) => {
      return {
        label:
          item.label.charAt(0).toUpperCase() +
          item.label.slice(1).toLowerCase(),
        value: item.value, // Assuming there is a 'value' property to maintain
        arabicLabel: item.arabicLabel,
      };
    });

    this.nationalities = this.nationalities.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });

    this.populateDurations();
    this.getRooms(this.eventID);
    const formID = this.formID;
    this.eventService.getEventByID(this.eventID).then((data) => {
      this.event = data.data();
    });
    const formInstance = this.formService.getFormByID(formID);
    formInstance.then((data) => {
      this.formData = data.data();
    });

    this.route.params.subscribe((params) => {
      const lang = params["lang"]; // Access the 'lang' parameter from the URL
      if (this.router.url.includes("lang=ar")) {
        this.setLanguage("ar");
        this.isArabic = true;
        // Perform actions specific to the 'ar' language
      } else {
        this.isArabic = false;
        this.setLanguage("en");
        // Perform actions for other languages
      }
    });
  }

  populateDurations() {
    this.duration = { label: "25 min", arabicLabel: "٢٥ دقيقة", value: 25 };
  }

  setLanguage(lang: string) {
    this.languageService.setLanguage(lang);
  }

  countryChange(e) {}

  async getRooms(eventID) {
    try {
      const roomsArray = await this.roomsService.getRoomsCollectionByEvent(
        eventID
      );

      // this.selectedDate = this.minDate;
      this.rooms = [];
      this.staticRooms = [];
      
      roomsArray.docs
        .map((doc) => {
          const roomData = doc.data();
          if (roomData?.deleted !== true) {
            this.rooms.push(roomData);
            this.staticRooms.push(roomData);
          }
        });
      
      // Sort alphabetically by roomName
      this.rooms.sort((a, b) => a.roomName.localeCompare(b.roomName));
      this.staticRooms.sort((a, b) => a.roomName.localeCompare(b.roomName));
      
      if (this.rooms.length > 0) {
        // this.formSubmit.patchValue({
        //   room: this.rooms[0].uid, // Replace with your desired value
        // });

        try {
          this.onRoomChange(this.rooms[0]);
        } catch (error) {}

        // this.minDate = this.formatDate(this.rooms[0]?.date[0].seconds); // Start date timestamp

        // const selectedDate = moment
        //   .unix(this.rooms[0]?.date[0].seconds)
        //   .add(1, "day");

        // Convert the moment object back to seconds
        // const selectedDateInSeconds = selectedDate.unix();

        // this.maxDate = this.formatDate(this.rooms[0]?.date[1].seconds); // End date timestamp

        // Get the current date as a formatted string
        // const currentDate = this.formatDate(Date.now() / 1000); // Current date timestamp

        // Set minDate to currentDate if it's in the past
        // this.minDate = this.minDate < currentDate ? currentDate : this.minDate;

        // this.bsConfig = {
        //   minDate: new Date(this.rooms[0]?.date[0].seconds * 1000),
        //   maxDate: new Date(this.rooms[0]?.date[1].seconds * 1000),
        //   // ... other configurations
        // };

        // this.selectedDate = this.minDate;
        this.formSubmit.patchValue({
          time: "",
          date: this.selectedDate,
        });
        this.extractDatesAndFindRange(this.rooms[0]);
      }
      // this.loading = false;
    } catch (error) {
      this.loading = false;
      this.rooms = []; // Set an empty array in case of an error
    }
  }

  getAMPM(time: string): string {
    const [hours] = time.split(":").map(Number);
    return hours >= 12 ? "pm" : "am";
  }

  getAMPMAR(time: string): string {
    const [hours] = time.split(":").map(Number);
    return hours >= 12 ? "م" : "ص";
  }

  findRoomByUid(uid: string): any {
    return this.rooms.find((room) => room.uid === uid);
  }

  formatDate(timestamp: number): string {
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  formatDateString(dateStr) {
    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric", // Use 'numeric' or '2-digit'
      month: "long", // 'long', 'short', 'narrow', or 'numeric'/'2-digit' can be used
      year: "numeric", // Use 'numeric' or '2-digit'
      timeZone: "Asia/Qatar", // Specify the timeZone as a string
    };
    return date.toLocaleDateString("en-US", options);
  }

  async fetchAllSubmits(id: any): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.submitService
        .getSubmitsByRoomID(id)
        .then((resp) => {
          let array = [];
          resp?.forEach((data, index) => {
            if (!data.deleted) {
              array.push(data);
            }
          });

          this.submits = array;

          resolve(this.submits); // Resolve the Promise with the fetched data
        })
        .catch((error) => {
          // Handle errors here

          reject(error); // Reject the Promise with the error
        });
    });
  }

  async onRoomChange(room) {
    if (room) {
      // Retrieve the selected value from the event object
      this.selectedRoom = room;

      //  alert((room?.dateList[0].date[0].seconds))
      // Calculate the minDate and maxDate based on the selected room's date values
      try {
        this.minDate = this.formatDate(room?.dateList[0].date[0].seconds); // Start date timestamp
      } catch (error) {
        this.minDate = null;
      }
      // const selectedDate = moment.unix(room?.date[0].seconds).add(1, "day");

      // Convert the moment object back to seconds
      // const selectedDateInSeconds = selectedDate.unix();

      // this.maxDate = this.formatDate(room?.date[1].seconds); // End date timestamp

      // Get the current date as a formatted string
      const currentDate = this.formatDate(Date.now() / 1000); // Current date timestamp

      // Set minDate to currentDate if it's in the past
      this.minDate = this.minDate < currentDate ? currentDate : this.minDate;
      this.extractDatesAndFindRange(room);
      if (!this.selectedDate) this.selectedDate = this.minDate;
      // this.formSubmit.get("date").setValue( this.minDate)
      // this.hours = [];
      // hours.forEach((hour) => {
      //   if (this.isDateHourFull(hour, this.minDate)) this.hours.push(hour);
      // });
      let allRoom = [];
      for (let room of this.staticRooms) {
        if (room.uid) {
          let roomHours = await this.selectableHours(
            room.startTime,
            room.endTime,
            room
          );
          let finalHours = [];
          roomHours.forEach((hour) => {
            if (this.isDateHourFull(hour, this.minDate)) finalHours.push(hour);
          });
          let hoursUpdated = await this.findSelectableHours(
            room,
            this.selectedDate
          );

          room.hours = hoursUpdated;

          let newRoom = {
            ...room,
            hours: hoursUpdated,
          };

          allRoom.push(newRoom);
        }
      }
      this.rooms = allRoom;
      this.onAttendeesChange(
        {
          target: {
            value: this.formSubmit.get("attendees").value,
          },
        },
        false
      );
    }
  }
  convertDateFormat(dateStr: string): string {
    const parts = dateStr.split("-"); // Split the date by '-'
    return `${parts[2]}/${parts[1]}/${parts[0]}`; // Rearrange the parts into DD/MM/YYYY format
  }

  async selectableHours(
    startTime: string,
    endTime: string,
    room
  ): Promise<string[]> {
    this.submits = [];
    this.loading = true;
    const data = await this.fetchAllSubmits(room.uid);
    const bookings = data;
    const startInteger = this.timeToMinutes(startTime);
    const endInteger = this.timeToMinutes(endTime);
    const hours = [];
    let durationInMinutes = this.duration.value + this.cleaningTime;
  
    console.log({bookings:bookings})
    for (let i = startInteger; i <= endInteger - durationInMinutes; i += 5) {
      const time = this.minutesToTime(i);
      let isAvailable = true;
  
      for (const booking of bookings) {

        const bookingStartTime = new Date(
          booking.data?.personalInformation1?.bookingStartTime
        ).getTime() + 60000; // Adding 60000 after parsing
        
        const bookingEndTime = new Date(
          booking.data.personalInformation1?.realBookingEndTime
        ).getTime();
        const currentTime = Date.UTC(
          Number(this.selectedDate.split("-")[0]), // Year
          Number(this.selectedDate.split("-")[1]) - 1, // Month (0-based)
          Number(this.selectedDate.split("-")[2]), // Day
          Number(time.split(":")[0]), // Hour
          Number(time.split(":")[1]) // Minute
        );
        
        const bookingEndTimeWithBuffer = bookingEndTime; // Buffer for booked duration
        console.log( (currentTime >= bookingStartTime &&
          currentTime < bookingEndTimeWithBuffer) ||
        this.checkTimeBetween(
          currentTime + durationInMinutes * 60000,
          bookingStartTime + 60000,
          bookingEndTime
        ) ||
        (currentTime < bookingStartTime + 60000 &&
          currentTime + durationInMinutes * 60000 > bookingStartTime)
     )
        if (
          (currentTime >= bookingStartTime &&
            currentTime < bookingEndTimeWithBuffer) ||
          this.checkTimeBetween(
            currentTime + durationInMinutes * 60000,
            bookingStartTime + 60000,
            bookingEndTime
          ) ||
          (currentTime < bookingStartTime + 60000 &&
            currentTime + durationInMinutes * 60000 > bookingStartTime)
        ) {
          isAvailable = false;
          break;
        }
      }
      const arabicTime = this.translateToArabic(time); // Assuming you have a function to translate to Arabic
    //  console.log({ value: time, isAvailable, arabicTime })
      if (isAvailable) hours.push({ value: time, isAvailable, arabicTime });
    }

    this.loading = false;
    return Promise.resolve(hours);
  }

  private populateDateList(): void {
    this.dateList = [];
    let minDate = new Date(this.minDate);
    let maxDate = new Date(this.maxDate);
    let currentDate = new Date(minDate.getTime());

    while (currentDate <= maxDate) {
      this.dateList.push(currentDate.toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  translateToArabic(time: string): string {
    // Assuming time is in the format 'HH:MM'
    const [hours, minutes] = time.split(":");
    const arabicHours = this.convertNumberToArabic(parseInt(hours));
    const arabicMinutes = this.convertNumberToArabic(parseInt(minutes));
    const paddedHours = (
      hours.length === 1 ? "٠" + arabicHours : arabicHours
    ).padStart(2, "٠");
    const paddedMinutes = (
      minutes.length === 1 ? "٠" + arabicMinutes : arabicMinutes
    ).padStart(2, "٠");
    return paddedHours + ":" + paddedMinutes;
  }

  convertNumberToArabic(number: number): string {
    // Define your own logic for converting numbers to Arabic
    // This is a simplified example
    if (this.isArabic) {
      const arabicNumbers = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
      return number
        .toString()
        .split("")
        .map((digit) => arabicNumbers[parseInt(digit)])
        .join("");
    }
    return number.toString();
  }

  checkTimeBetween(
    currentTime: number,
    startTime: number,
    endTime: number
  ): boolean {
    return currentTime >= startTime && currentTime <= endTime;
  }

  // selectableHours(startTime: string, endTime: string): string[] {
  //   const startInteger = this.timeToMinutes(startTime);
  //   const endInteger = this.timeToMinutes(endTime);
  //   const hours: string[] = [];

  //   for (let i = startInteger; i <= endInteger; i += 5) {
  //     hours.push(this.minutesToTime(i));
  //   }
  //
  //
  //   return hours;

  // }

  // selectableHours(startTime: any, endTime: any): number[] {
  //   const hours = [];
  //   for (
  //     let i = this.timeToInteger(startTime);
  //     i <= this.timeToInteger(endTime);
  //     i++
  //   ) {
  //     hours.push(i.toString());
  //   }
  //
  //
  //   return hours;
  // }

  timeToMinutes(time: string): number {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  }

  minutesToTime(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${this.padZero(hours)}:${this.padZero(mins)}`;
  }

  padZero(num: number): string {
    return num < 10 ? `0${num}` : num.toString();
  }

  timeToInteger(timeString) {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours;
  }
  integerToTime(hours: number): string {
    // Ensure the hours are within a valid range (0-23)
    hours = Math.min(23, Math.max(0, hours));

    // Format the hours as two digits
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;

    // Return the formatted time string with minutes set to "00"
    return `${formattedHours}:00`;
  }

  toggleOtherTitleField() {
    // Determine whether to show the "Other Title" field based on the selected value
    const selectedTitle = this.formSubmit.get("title").value;
    if (selectedTitle === "other") {
      this.formSubmit.get("otherTitle").enable(); // Show the field
    } else {
      this.formSubmit.get("otherTitle").disable(); // Hide the field
    }
  }

  showOtherTitleField() {
    // Check if the "Other Title" field should be shown
    return this.formSubmit.get("title").value === "other";
  }

  isDateHourFull(hour, date): boolean {
    const data = this.selectedRoom;
    const key = `${date}:${hour}`;
    //
    if (data[key] !== undefined && data.capacity !== undefined) {
      //
      return data[key] < data.capacity;
    }
    //
    //
    //
    //
    return true;
  }

  async onSubmit() {
    if (this.formSubmit.valid) {
      // Form is valid, proceed with submission
      const formData = this.formSubmit.value;
      try {
        this.loading = true;
        const db = firebase.firestore();
        const ref = db.collection("submits").doc();
        const id = ref.id;

        const room = this.findRoomByUid(formData.room);
        const date = formData.date; // Assuming the format is YYYY-MM-DD
        const startTime = formData.time.value; // Assuming the format is HH:mm
        const duration = parseInt(formData.duration); // Assuming the duration is in minutes

        // Convert date and time into a JavaScript Date object
        const [hours, minutes] = startTime.split(":").map(Number);
        const [year, month, day] = date.split("-").map(Number);
        const bookingStartTime = new Date(
          Date.UTC(year, month - 1, day, hours, minutes)
        );
        const categoryRef = await this.categoryService.getCategoriesByID(
          "h06PQhpdeRc1K92cFQpQ"
        );
        let category = categoryRef.data();
        // Calculate the end time based on the start time and duration
        const bookingEndTime = new Date(
          bookingStartTime.getTime() + duration * 60000
        ); // Duration in milliseconds

        const realBookingEndTime = new Date(
          bookingStartTime.getTime() + (duration + this.cleaningTime) * 60000
        ); // Duration in milliseconds
        const submitNumber = this.event?.submitsCounter?.submits | 0;

        const data = {
          categoryID: category?.uid,
          data: {
            code: `${this.event.code}-${this.getFirstSixChars(id)}`,
            category: category?.name,
            categoryClass: category?.categoryClass
              ? category?.categoryClass
              : "",
            personalInformation1: {
              ...formData,
              bookingStartTime: bookingStartTime.toUTCString(),
              bookingEndTime: bookingEndTime.toUTCString(),
              realBookingEndTime: realBookingEndTime.toUTCString(),
              cleaningTime: this.cleaningTime,
              roomName: this.selectedRoom?.roomName,
              room: this.selectedRoom?.uid,
              code: `${this.event.code}-${this.getFirstSixChars(id)}`,
            },
          },
          eventID: this.eventID,
          formID: this.formID,
          uid: id,
        };

        const hasCapacity = await this.roomsService.checkCapacity(
          this.selectedRoom.uid,
          this.timeToInteger(formData.time.value),
          formData.date
        );
        if (hasCapacity) {
          if(this.autoApprove){
            await this.formService.submitFormApproved(data);
          }
          else{
            await this.formService.submitForm(data);
          }
        
          await this.roomsService.incrementRoom(
            this.selectedRoom.uid,
            this.timeToInteger(formData.time.value),
            formData.date
          );

          const emailData = {
            category: category?.name,
            categoryClass: category?.categoryClass
              ? category?.categoryClass
              : "",
            submitID: id,
            code: `${this.event.code}-${this.getFirstSixChars(id)}`,
            ...data.data,
            ...formData,
            ...{
              selectedTime: formData?.time?.value,
            },
            ...formData?.time,
            ...formData.phoneNumber,
            url:
              "https://diggri.com/thankyou/" + category?.tyPage?.uid + "/" + id,
            roomName: this.selectedRoom.roomName,
          };
          this.event;
          category = {
            ...this.event,
            ...category,
          };
          // Send confirmation email
          if(this.autoApprove){
            let emailResponse = await this.submitService.sendEmail(
              emailData,
              "approve",
              category,
              category,
              id
            );
          }
          else{
            let emailResponse = await this.submitService.sendEmail(
              emailData,
              "register",
              category,
              category,
              id
            );
          }
        

          this.loading = false;

          if (category?.tyPage?.uid) {
            this.router.navigate([
              "/thankyou/" + category.tyPage.uid + "/" + id,
            ]);
          } else
            this.router.navigate([
              "/thankyou",
              {
                typ1: category?.typ1
                  ? category?.typ1
                  : "Registration Successful",
                typ2: category?.typ2
                  ? category?.typ2
                  : "Thank you, your registration has been successful. Our team will contact you within 24-48 hours.",
              },
            ]);
        } else {
          alert("full");
        }
      } catch (error) {
        this.loading = false;
      }
    } else {
      // Form is invalid, show error messages

      this.formSubmit.markAllAsTouched();

      // Loop through formSubmit controls and log error messages
      Object.keys(this.formSubmit.controls).forEach((controlName) => {
        const control = this.formSubmit.get(controlName);

        if (control.invalid) {
          const errors = control.errors;
          Object.keys(errors).forEach((errorName) => {});
        }
      });
    }
  }

  onAttendeesChange(event: any, changeSelectedRoom) {
    let selectedAttendees = 0; // Extract the selected number of attendees from the event
    if (event?.target?.value) {
      selectedAttendees = event?.target?.value;
    } else {
      selectedAttendees = this.formSubmit.get("attendees").value;
    }

    if (changeSelectedRoom) {
      this.selectedRoom = null;
      this.formSubmit.patchValue({
        room: "", // Replace with your desired value
        time: "",
      });
    }

    // Assuming you have an array of rooms with a 'capacity' property
    const availableRooms = this.staticRooms.filter(
      (room) => room.capacity >= selectedAttendees
    );
    if (this.rooms != this.staticRooms) {
      this.rooms = availableRooms;
    }

    // You can update the available rooms list in your form or do other necessary operations
  }

  selectHour(hour: string, room: any) {
    this.formSubmit.patchValue({
      room: room, // Replace selectedRoomValue with the value you want to set
      time: hour,
    });
    this.selectedRoom = room;
    // Do something with the selected hour, such as assigning it to a variable
    this.selectedHour = hour;
    // You can perform other actions here based on the selected hour
  }

  onDurationChange(event) {
    const duration = event;
    this.selectedRoom = null;
    this.formSubmit.patchValue({
      room: "", // Replace with your desired value
      time: "",
    });
    if (duration) {
      const selectedDuration = this.durations.find((duration) => {
        return duration.value === parseInt(event, 10);
      });

      this.duration = selectedDuration;

      this.onRoomChange(this.selectedRoom);
    } else {
      this.duration = { label: "25 min", arabicLabel: "٢٥ دقيقة", value: 25 };
      this.onRoomChange(this.selectedRoom);
    }
  }
  numberToFourDigits(number) {
    return number.toString().padStart(4, "0");
  }

  updateLanguage(event) {
    this.selectedLanguage = event.target.value;
    // Check if the user selected Arabic
    if (this.selectedLanguage === "Arabic") {
      // If the current URL doesn't contain 'lang=ar', navigate to the same URL with 'lang=ar' added
      if (!this.router.url.includes("lang=ar")) {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { lang: "ar" },
          queryParamsHandling: "merge",
        });
        const newUrl = this.router.url + "?lang=ar"; // Remove the lang=ar parameter
        window.location.href = newUrl; // Reload the page with the modified URL
      }
    } else {
      // If the user selects English, navigate to the same URL without 'lang=ar' and reload the page
      if (this.router.url.includes("lang=ar")) {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { lang: null },
          queryParamsHandling: "merge",
        });
        const newUrl = this.router.url.split("?lang=ar")[0]; // Remove the lang=ar parameter
        window.location.href = newUrl; // Reload the page with the modified URL
      }
    }
  }

  phoneChange(e) {
    this.phoneNumber = e;

    // this.formSubmit.patchValue({
    //   phoneNumber: e,
    // });
  }

  // extractDatesAndFindRange(roomObject) {
  //   let allDates = [];

  //   // Loop through dateList to extract and add all start and end dates to the allDates array
  //   roomObject.dateList.forEach((dateItem) => {
  //     let startDate = new Date(dateItem.date[0].seconds * 1000);
  //     startDate.setHours(0, 0, 0, 0);
  //     let endDate = new Date(dateItem.date[1].seconds * 1000);
  //     endDate.setHours(0, 0, 0, 0);
  //     allDates.push(startDate, endDate);
  //   });

  //   // Sort allDates to ensure they are in chronological order
  //   // allDates.sort((a, b) => a - b);

  //   // Set today's date to the start of the day for comparison
  //   const today = new Date();
  //   today.setHours(0, 0, 0, 0);

  //   // Find the earliest date that is not in the past
  //   const earliestDateIndex = allDates.findIndex(date => date >= today);
  //   let earliestDate = allDates[0] || null;
  //   let latestDate = allDates[allDates.length - 1];
  //   latestDate.setHours(0, 0, 0, 0);
  //   earliestDate.setHours(0, 0, 0, 0);

  //   // If there are no future dates, handle this case accordingly
  //   if (earliestDate === null) {
  //       // No future dates found, handle accordingly, perhaps by setting default values or returning early
  //       return { earliestDate: null, latestDate: null };
  //   }

  //   // Initialize this.dateList and populate it with every date from earliestDate to latestDate
  //   this.dateList = []; // Assuming 'this' is the context of the component or class containing this method
  //   let currentDate = new Date();
  //   currentDate.setHours(0, 0, 0, 0);
  //   let present = new Date();
  //   present.setHours(0, 0, 0, 0);
  //   let list = []
  //   while (currentDate <= latestDate ) {

  //     currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  //     currentDate.setHours(0, 0, 0, 0);
  //     list.push(currentDate.toISOString().split("T")[0]);
  //   }
  //   this.dateList = list;
  //   // Return the earliest and latest dates for any additional needs
  //   return { earliestDate, latestDate };
  // }

  extractDatesAndFindRange(roomObject) {
    this.dateList = []; // Initialize or reset the date list

    // Loop through each date range in the roomObject's dateList
    roomObject.dateList.forEach((dateItem) => {
      let startDate = new Date(dateItem.date[0].seconds * 1000);
      startDate.setUTCHours(0, 0, 0, 0); // Normalize to the start of the day in UTC
      let endDate = new Date(dateItem.date[1].seconds * 1000);
      endDate.setUTCHours(0, 0, 0, 0); // Normalize to the start of the day in UTC

      // Add one day to the endDate to make the range inclusive
      endDate = new Date(endDate.setDate(endDate.getDate() + 1));
      let today = new Date();
      today.setUTCHours(0, 0, 0, 0); // Normalize to the start of the day in UTC
      // Populate this.dateList with each date in the range
      for (
        let currentDate = new Date(startDate);
        currentDate < endDate;
        currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
      ) {
        if (today <= currentDate)
          this.dateList.push(currentDate.toISOString().split("T")[0]);
      }
    });

    // Remove duplicates and sort the date list to ensure chronological order
    this.dateList = [...new Set(this.dateList)].sort();

    // Logging for verification

    // Determine the overall earliest and latest dates from the sorted list
    let earliestDate =
      this.dateList.length > 0 ? new Date(this.dateList[0]) : null;
    let latestDate =
      this.dateList.length > 0
        ? new Date(this.dateList[this.dateList.length - 1])
        : null;

    return { earliestDate, latestDate };
  }

  async findSelectableHours(roomObject, selectedDateString) {
    const selectedDate = new Date(selectedDateString + "T00:00:00Z");
    selectedDate.setUTCHours(0, 0, 0, 0); // Reset time to midnight for comparison
    let matchingHours = [];

    // Loop through each date range to find a match for the selected date
    for (let dateRangeItem of roomObject.dateList) {
      const startDate = new Date(dateRangeItem.date[0].seconds * 1000);
      startDate.setUTCHours(0, 0, 0, 0); // Reset time to midnight
      const endDate = new Date(dateRangeItem.date[1].seconds * 1000);
      endDate.setUTCHours(0, 0, 0, 0); // Reset time to midnight

      // Check if the selected date falls within the current date range
      if (selectedDate >= startDate && selectedDate <= endDate) {
        // If it does, map the timeList for this date range to matchingHours
        matchingHours = dateRangeItem.timeList.map((timeSlot) => ({
          startTime: timeSlot.startTime,
          endTime: timeSlot.endTime,
        }));
        // Once a match is found, break out of the loop as we don't need to check further
        break;
      }
    }
    console.log({matchingHours})
    // If matching hours were found for the selected date, process them
    if (matchingHours.length > 0) {
      let times = [];
      for (let time of matchingHours) {
        // It's unclear what selectableHours does, assuming it's a method to further process or validate times
        const hours = await this.selectableHours(
          time.startTime,
          time.endTime,
          roomObject
        );
        times = times.concat(hours);
      }
      return times;
    } else {
      return []; // Return an empty array if no matching date range was found
    }
  }
  getFirstSixChars(id: string): string {
    return id.substring(0, 6);
  }
}
