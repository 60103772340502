import { Component, Inject, Injector, OnInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/core/services/auth.service";
import { CompanyEventsService } from "src/app/core/services/company-events.service";
import { EventService } from "src/app/core/services/event.service";
import { FormService } from "src/app/core/services/form.service";
import { SubmitService } from "src/app/core/services/submits.service";
import { firebase } from "src/app/fbInitialize";
import type {} from "css-font-loading-module";
import {
  registerCustomComponent,
  registerCustomComponentCropImage,
  registerCustomComponentEventRoomsPicker,
  registerCustomComponentRoomPicker,
  registerCustomComponentTimePicker,
} from "src/app/formio/rating-wrapper.formio";
import { TranslateService } from "@ngx-translate/core";
import { TranslationsService } from "src/app/core/services/translations.service";
import values from "../form/countries.translations";
import { CategoriesService } from "src/app/core/services/categories.service";
import AWS from "aws-sdk";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormFilledComponent implements OnInit {
  public editFormID: any;
  breadCrumbItems: Array<{}>;
  form: any;
  submitted = false;
  error = "";
  successmsg = false;
  inputs: Array<{}>;
  formx: any;
  loading = false;
  submitData: any;
  hasCategory: any;
  event: any;
  font: any;
  category: any;
  stylesObj: any;
  email: any;
  photo = false;
  language = "english";
  lang: any;
  languageFromURL: any = "eng";
  countryNames: any = {}; // Store translated country names
  isClosed = false;
  categoryData: any = {
    disabled: false,
  };

  constructor(
    private router: Router,
    private injector: Injector,
    private formService: FormService,
    private submitService: SubmitService,
    private authService: AuthenticationService,
    private eventService: CompanyEventsService,
    private route: ActivatedRoute,
    private translationService: TranslationsService,
    private translateService: TranslateService,
    private translate: TranslateService,
    private categoryService: CategoriesService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    registerCustomComponent(injector);
    registerCustomComponentCropImage(injector);
    registerCustomComponentEventRoomsPicker(injector);
    registerCustomComponentTimePicker(injector);
    registerCustomComponentRoomPicker(injector);
  }

  ngOnInit(): void {
    const routeParams = this.router.routerState.snapshot;

    this.breadCrumbItems = [
      { label: "Admin" },
      { label: "Users" },
      { label: "Edit User", active: true },
    ];

    // const length = routeParams.url.split("/").length;

    // if (length == 4) {
    //   if (routeParams.url.split("/")[length - 1] == "photos") {
    //     this.photo = true;
    //   }
    //   this.editFormID = routeParams.url.split("/")[length - 2];
    // } else {
    //   this.editFormID = routeParams.url.split("/")[length - 1];
    // }

    this.editFormID = this.route.snapshot.paramMap.get("formid");
    this.route.queryParams.subscribe(async (params) => {
      if (params["lang"]) this.languageFromURL = params["lang"];
    });
    this.route.paramMap.subscribe((params) => {
      this.editFormID = params.get("formid");
      console.log("Form ID:", this.editFormID);
    });
   
    this.submitService.getSubmitByID(this.editFormID).then(async(res) => {
      let varName = "";

      let data: any;
      res.docs.forEach((res) => {
        data = res.data();
      });

      const updateData = {
        data:{
          ...data.data,
          
          tracking:{
            ...data.data.tracking,
            didSubmit:false,
          rsvp:true,
          rejectedInvite:false
          }
        }
      }
       await this.submitService.updateSubmit(data.uid, updateData);
      for (let [key, value] of Object.entries(data.data)) {
        if (key.includes("personal")) {
          varName = key;
        }
      }
      if (data.passportPictureURL) {
        this.toDataURL(data.passportPictureURL, function (dataUrl) {
          let image = [
            {
              hash: "afbecb356e7368b25bb0fce694dbddf5",
              name: "photo-1675882350147-fa2b1c94-06a6-414d-a5ba-3998279bf31d.png",
              originalName: "photo-1675882350147.png",
              storage: "base64",
              type: "image/png",
              url: dataUrl,
            },
          ];
          data.data[varName].passportPhoto = image;
          // data.data[varName].profilePhoto = image
          // data.data[varName].profilePhoto = dataUrl
        });
      }
      if (data.profilePictureURL) {
        this.toDataURL(data.profilePictureURL, function (dataUrl) {
          let image = [
            {
              hash: "afbecb356e7368b25bb0fce694dbddf5",
              name: "photo-1675882350147-fa2b1c94-06a6-414d-a5ba-3998279bf31d.png",
              originalName: "photo-1675882350147.png",
              storage: "base64",
              type: "image/png",
              url: dataUrl,
            },
          ];
          // data.data[varName].passportPhoto = image
          data.data[varName].profilePhoto = image;
          // data.data[varName].profilePhoto = dataUrl
        });
      }
      this.email = data.uid;

      this.submitData = data;
      delete this.submitData.data.language;
      Object.keys(this.submitData.data).forEach((element) => {
        let length = element.length;
        for (let i = 0; i < length - 1; i++) {
          const str = element[i] || "";

          if (
            element[i] == element[i].toUpperCase() &&
            element[i + 1] == element[i + 1].toUpperCase()
          ) {
            data.data[element.substring(0, length - 2)] = data.data[element];
            delete data.data[element];
          }
        }
      });
      this.formService.getFormByID(data.formID).then(async (res: any) => {
        this.formx = res.data();
        this.loadDynamicStyles(this.formx?.styles);
        console.log({formx:this.formx})
        await this.getCategoryData();
        try {
          if (this.photo) {
            if (this.formx.inputs[0].components[0].components) {
              //  this.formx.inputs[0].components = [this.formx.inputs[0].components[0]]
              this.formx.inputs[0].components[0].components = [
                ...this.formx.inputs[0].components[0].components.slice(0, 2),
                this.formx.inputs[0].components[0].components[
                  this.formx.inputs[0].components[0].components.length - 1
                ],
              ];
            } else {
              this.formx.inputs[0].components[1].components = [
                ...this.formx.inputs[0].components[1].components?.slice(0, 2),
                this.formx.inputs[0].components[1].components[
                  this.formx.inputs[0].components[1].components.length - 1
                ],
              ];
            }
          }
        } catch (error) {}
        let translationsArray = await this.translationService
          .getTranslationsCollection()
          .get()
          .then((data) => {
            return data.docs.map((doc) => doc.data());
          })
          .catch((error) => {
            return [];
          });

        translationsArray = [...translationsArray, ...values];
        console.log({ languageFromURL: this.languageFromURL });
        if (this.languageFromURL === "ar") {
          //
          //
          this.formx.inputs = this.translateLabels(
            this.formx.inputs,
            translationsArray
          );
          // Apply RTL class to Formio

          const formioElement = document.querySelector(".formio");
          if (formioElement) {
            formioElement.classList.add("rtl-formio padding-right-5");
          }
          this.language = "lt";
          this.lang = {
            language: "lt",
            i18n: {
              lt: {
                "is required": "مطلوب",
                "is required ": "مطلوب",
                browse: "تصفح",
                "Enter Phone no": "أدخل رقم الهاتف",
                "Type to search": "اكتب للبحث",
                or: "أو",
                "Drop files to attach,": "قم بإسقاط الملفات لإرفاقها ",
                // Add other translations
              },
            },
          };
          // Load translations here
          this.translateService
            .get("countryCodes")
            .subscribe((translations: any) => {
              this.countryNames = translations;
            });

          // Check if the language is 'ar'

          if (formioElement) {
            formioElement.classList.add("rtl-formio p-2");
          }
       
          this.loading = false;
          // Switch to the Arabic language
          this.translate.use("ar");
        }
        if (this.languageFromURL === "fr") {
          //
          //
          this.formx.inputs = this.translateLabels(
            this.formx.inputs,
            translationsArray
          );
          // Apply RTL class to Formio

          const formioElement = document.querySelector(".formio");
         
          this.language = "lt";
          this.lang = {
            language: "lt",
            i18n: {
              lt: {
                "is required": "est requis",
                "is required ": "est requis",
                browse: "Parcourir",
                "Enter Phone no": "Entrez le numéro de téléphone",
                "Type to search": "Tapez pour rechercher",
                or: "ou",
                "Drop files to attach,": "Déposez les fichiers à joindre,",
                // Add other translations
              },
            },
          };
          
          // Load translations here
          this.translateService
            .get("countryCodes")
            .subscribe((translations: any) => {
              this.countryNames = translations;
            });

          // Check if the language is 'ar'

      
          this.loading = false;
          // Switch to the Arabic language
          this.translate.use("fr");
        }
        if (this.formx?.inputs) {
          this.form = {
            components: this.formx.inputs,
          };
        }
        this.form = {
          components: this.formx.inputs,
        };
        let array = [];
        this.formx?.event.forEach((element) => {
          array.push(element.uid);
        });
        this.eventService.getEventByID(this.formx.event[0].uid).then((data) => {
          this.event = data.data();
          this.font = this?.event?.font_url;
          if (this.font) {
            var f = new FontFace("fingerpaint", `url(${this.font})`, {});
            document.fonts.add(f);
            document.getElementById("font").style.fontFamily = "fingerpaint";
          }
        });
      });
    });
  }

  getCategoryData = async () => {
    try {
      console.log({submitData:this.submitData})
      const data = await this.categoryService.getCategoriesByID(
        this.submitData.categoryID
      );
      const category = data.data();
      if (category) this.categoryData = category;
      this.category = this.categoryData;
    } catch (error) {
      // Handle error
    }
  };

  handleUpload(
    photoType: string,
    urlKey: string,
    fileName: string,
    data: any,
    id: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        let varName = "";
        for (const [key, value] of Object.entries(data.data)) {
          if (key.includes("personal")) {
            varName = key;
            break;
          }
        }
        console.log(data.data[varName][photoType]);
        console.log(320);
        const base64Data = data.data[varName][photoType][0].url
          .split("base64,")
          .pop();
        console.log({ base64Data });
        const mimeType = this.getFileTypeFromBase64(base64Data); // Adjust the mime type accordingly
        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
          const slice = byteCharacters.slice(offset, offset + 1024);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const photoBlob = new Blob(byteArrays, { type: mimeType });
        const filePath =
          fileName === "PP"
            ? `passports/${id}_${fileName}.jpg`
            : `profiles/${id}_${fileName}.jpg`;

        const s3 = new AWS.S3({
          accessKeyId: "AKIA5J6SD6XVX4LRHKIW",
          secretAccessKey: "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",
          region: "eu-central-1",
        });

        const params = {
          Bucket: "diggri",
          Key: filePath,
          Body: photoBlob,
          ContentType: mimeType,
        };

        s3.upload(params, async (err, res) => {
          if (err) {
            reject(err);
          } else {
            // if (photoType === "passportPhoto") {
            //   let scannedData = await this.mindeeService
            //     .scanPassport(res.Location)
            //     .catch((error) => {
            //       this.loading = false;
            //       alert("Something went wrong");
            //     });

            //   this.scannedData = scannedData;
            //   data.scannedData = scannedData;
            // }
            delete data.data[varName][photoType];

            if (photoType === "profilePhoto") {
              data.data[varName].profilePictureURL = res.Location;
              data.profilePictureURL = res.Location;
            }
            if (photoType === "passportPhoto") {
              data.data[varName].passportPictureURL = res.Location;
              data.passportPictureURL = res.Location;
            }
            // if (data.data[varName][photoType])
            // data[urlKey] = data.data[varName][photoType];

            resolve(res.Location);
          }
        });
      } catch (error) {
        resolve();
      }
    });
  }

  translateLabels(components: any[], translations: any[]) {
    components.forEach((component: any) => {
      if (component.label) {
        const matchingTranslation = translations.find((translation) => {
          return (
            translation.name.toLowerCase().replace(/\s/g, "") ===
            component.label.toLowerCase().replace(/\s/g, "")
          );
        });
        console.log({matchingTranslation})
        if (matchingTranslation) {
          component.label = matchingTranslation[this.languageFromURL]; // Replace with the translated label
        }
      }
      if (component?.validate?.customMessage) {
        const matchingTranslation = translations.find((translation) => {
          return (
            translation.name.toLowerCase().replace(/\s/g, "") ===
            component.validate.customMessage.toLowerCase().replace(/\s/g, "")
          );
        });
        if (matchingTranslation) {
          component.validate.customMessage = matchingTranslation[this.languageFromURL]; // Replace with the translated label
        }
      }
      if (component.placeholder) {
        const matchingTranslation = translations.find(
          (translation) =>
            translation.name.toLowerCase().replace(/\s/g, "") ===
            component.placeholder.toLowerCase().replace(/\s/g, "")
        );
        if (matchingTranslation) {
          component.placeholder = matchingTranslation[this.languageFromURL]; // Replace with the translated placeholder
        }
      }

      if (component.description) {
        const matchingTranslation = translations.find(
          (translation) =>
            translation.name.toLowerCase().replace(/\s/g, "") ===
            component.description.toLowerCase().replace(/\s/g, "")
        );
        if (matchingTranslation) {
          component.description = matchingTranslation[this.languageFromURL]; // Replace with the translated placeholder
        }
      }

      // Translate options if applicable
      if (component.data && component.data.values) {
        for (const option of component.data.values) {
          const matchingTranslation = translations.find(
            (translation) =>
              translation.name.toLowerCase().replace(/\s/g, "") ===
              option.label.toLowerCase().replace(/\s/g, "")
          );
          if (matchingTranslation) {
            option.label = matchingTranslation[this.languageFromURL]; // Replace with the translated option label
          }
        }
      }

      if (component.fields) {
        for (const key in component.fields) {
          if (component.fields.hasOwnProperty(key)) {
            const field = component.fields[key];
            if (field.placeholder) {
              const placeholderToMatch = field.placeholder
                .toLowerCase()
                .replace(/\s/g, "");
              const matchingTranslation = translations.find(
                (translation) =>
                  translation.name.toLowerCase().replace(/\s/g, "") ===
                  placeholderToMatch
              );
              if (matchingTranslation) {
                field.placeholder = matchingTranslation[this.languageFromURL]; // Replace with the translated placeholder
              }
            }
          }
        }
      }

      if (component.data && component.data.values) {
        component.data.values.forEach((value: any) => {
          if (value.label) {
            const labelToMatch = value.label.toLowerCase().replace(/\s/g, "");
            const matchingTranslation = translations.find(
              (translation) =>
                translation.name.toLowerCase().replace(/\s/g, "") ===
                labelToMatch
            );
            if (matchingTranslation) {
              value.label = matchingTranslation[this.languageFromURL]; // Replace with the translated label
            }
          }
        });
      }

      // Recursively traverse nested components
      if (component.components) {
        this.translateLabels(component.components, translations);
      }
      if (component.columns) {
        component.columns.forEach((column: any) => {
          if (column.components) {
            this.translateLabels(column.components, translations);
          }
        });
      }
    });

    return components;
  }

  async onSubmit(formData: any) {
    this.loading = true;
    const id = this.submitData.uid;
    const data = {
      ...formData,
      uid: this.submitData.uid,
    };

    let varName = "";
    let boolProfile = false;
    let boolPassport = false;

    const uploadTasks = [];
    for (const [key, value] of Object.entries(data.data)) {
      if (key.includes("personal")) {
        varName = key;
        const innerValue = data.data[key];
        boolProfile = innerValue && innerValue.hasOwnProperty("profilePhoto");
        boolPassport = innerValue && innerValue.hasOwnProperty("passportPhoto");
        break;
      }
    }
    if (boolProfile || boolPassport) {
      uploadTasks.push(
        this.handleUpload(
          "passportPhoto",
          "passportPictureURL",
          "PP",
          data,
          id
        ),
        this.handleUpload("profilePhoto", "profilePictureURL", "PH", data, id)
      );
    } else if (boolPassport) {
      let varName = "";
      for (const [key, value] of Object.entries(data.data)) {
        if (key.includes("personal")) {
          varName = key;
          break;
        }
      }

      uploadTasks.push(
        this.handleUpload("passportPhoto", "passportPictureURL", "PP", data, id)
      );
    } else if (boolProfile) {
      uploadTasks.push(
        this.handleUpload("profilePhoto", "profilePictureURL", "PH", data, id)
      );
    }

    try {
      Promise.all(uploadTasks)
        .then((data: any) => {
          this.replaceUndefinedWithEmptyString(data);
          return data;
        })
        .then((finalData) => {
          this.eventService
            .getEventByID(this.submitData.eventID)
            .then(async (res) => {
              let category = this.category;
              this.event = res.data();
              const submitNumber = this?.event?.submitsCounter?.submits | 0;
              let data: any = {
                passportPictureURL: finalData["0"] ? finalData["0"] : "",
                profilePictureURL: finalData["1"] ? finalData["1"] : "",

                data: {
                  usedLanguage: this.languageFromURL,
                  passportPictureURL: finalData["0"] ? finalData["0"] : "",
                  profilePictureURL: finalData["1"] ? finalData["1"] : "",
                  ...formData.data,
                  code: `${this.event.code}-${this.numberToFourDigits(
                    submitNumber + 1
                  )}`,
                  tracking:{
                         rejectedInvite:false,
                    didSubmit:true,
                  rsvp:true
                  }
                },
                eventID: this.submitData.eventID,
                //   formID: "At1A1rR1eoRc6NPeXe0a",
                uid: id,
                formID: this.submitData.formID,
                eventsIDs: this.formx.eventsIDs,
                // company: this.formx.company,
              };
              if (this.category?.categoryID)
                data = {
                  ...data,
                
                  didSubmit:true,
                  category: category?.name,
                  categoryClass: category?.categoryClass
                    ? category?.categoryClass
                    : "",
                  categoryID: this.category.categoryID,
                 
                };
              console.log("uid", this.submitData.uid);
              console.log({category:this.category})
              await this.submitService.updateSubmit(this.submitData.uid, data);


              if (this.category.uid) {

                // Send confirmation email
                const emailData = {
                  ...data.data?.scannedData,
                  ...data.data.personalInformation1,
                  language: this.languageFromURL,
                  code: data.data.code,
                  url:
                    "https://diggri.com/thankyou/" +
                    category?.tyPage?.uid +
                    "/" +
                    id,
                  ...data.data?.personalInformation1?.selectTimeSlot,
                };
                await this.submitService.sendEmail(
                  emailData,
                  "register",
                  category,
                  this.event,
                  formData.uid
                );
              }

              if (this.category?.tyPage?.uid) {
                this.router.navigate([
                  "/thankyou/" + category?.tyPage?.uid + "/" + id,
                ]);
              } else
                this.router.navigate([
                  "/thankyou",
                  {
                    typ1: category?.typ1
                      ? category?.typ1
                      : "Registration Successful",
                    typ2: category?.typ2
                      ? category?.typ2
                      : "Thank you, your registration has been successful. Our team will contact you within 24-48 hours.",
                  },
                ]);

              if (category?.tyPage?.uid) {
                this.router.navigate([
                  "/thankyou/" + category?.tyPage?.uid + "/" + id,
                ]);
              } else
                this.router.navigate([
                  "/thankyou",
                  {
                    typ1: category?.typ1
                      ? category?.typ1
                      : "Registration Successful",
                    typ2: category?.typ2
                      ? category?.typ2
                      : "Thank you, your registration has been successful. Our team will contact you within 24-48 hours.",
                  },
                ]);

              this.loading = false;
            });
        });
    } catch (error) {
      this.loading = false;
    }
  }

  private loadDynamicStyles(cssString: string) {
    const styleElement = this.renderer.createElement('style');
    this.renderer.setProperty(styleElement, 'textContent', cssString);  // Use textContent for security over innerHTML
    this.renderer.appendChild(this.document.head, styleElement);
  }

  timeToInteger(timeString) {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours;
  }

  replaceUndefinedWithEmptyString(obj) {
    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        this.replaceUndefinedWithEmptyString(obj[key]); // Recursively check nested objects
      } else if (
        typeof obj[key] === "undefined" ||
        !obj[key] ||
        obj[key] === null ||
        obj[key] === ""
      ) {
        obj[key] = ""; // Replace undefined with an empty string
      }
    }
  }
  isUserAdminOrOwner() {
    const user = this.authService.currentUserValue;
    return user.roles.admin || user.roles.owner;
  }
  showVisa(url) {
    url ? window.open(url) : alert("No Passport Image Found");
  }
  _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }
  getFileTypeFromBase64(base64String) {
    console.log("Base64 String:", base64String.substring(0, 50)); // Log the beginning of the string
    const regex = /^data:(.*?);base64,/; // Regular expression to extract MIME type
    const match = base64String.match(regex);
    console.log({ match });
    return match ? match[1] : null; // Returns the MIME type if matched, otherwise null
  }
  numberToFourDigits(number) {
    return number.toString().padStart(4, "0");
  }
}
