import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, FormBuilder } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

// import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/core';
// import interactionPlugin from '@fullcalendar/interaction';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
// import listPlugin from '@fullcalendar/list';

import { category, calendarEvents, createEventId, nationalities, titles } from './data';

import Swal from 'sweetalert2';
import { CategoriesService } from 'src/app/core/services/categories.service';
import { Router } from '@angular/router';
import { RoomsService } from 'src/app/core/services/rooms.service';
import { FormService } from 'src/app/core/services/form.service';
import { SubmitService } from 'src/app/core/services/submits.service';
import { CompanyEventsService } from 'src/app/core/services/company-events.service';


import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from "ngx-intl-tel-input";
import {  FormGroup } from "@angular/forms";
import { firebase } from "../../fbInitialize";


@Component({
  selector: "app-rooms-bookings",
  templateUrl: "./rooms-bookings.component.html",
  styleUrls: ["./rooms-bookings.component.scss"],
})

export class RoomsBookingsComponent implements OnInit {

  modalRef?: BsModalRef;

  // bread crumb items
  breadCrumbItems: Array<{}>;

  @ViewChild('modalShow') modalShow: TemplateRef<any>;
  @ViewChild('editmodalShow') editmodalShow: TemplateRef<any>;

  formEditData: UntypedFormGroup;
  submitted = false;
  category: any[];
  newEventDate: any;
  editEvent: any;
  calendarEvents: any[];
  // event form
  formData: UntypedFormGroup;

  // calendarOptions: CalendarOptions = {
  //   selectConstraint: {
  //     start: '00:30', // minimum duration of 30 minutes
  //     end: '01:00' // maximum duration of 1 hour
  //   },
  // //   plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
  // //  // hiddenDays: [0, 6], // 0 for Sunday, 6 for Saturday. Hide specific days of the week
  // //   slotMinTime: `${"00:30"}:00`, // Replace with your minimum time selectedRoom
  // //   slotMaxTime: `${"01:00"}:00`, // Replace with your maximum time
  // //   //slotDuration: "00:30:00", // Set the slot duration to 30 minutes
  // //   dayMaxEventRows: 0,
  // //   allDaySlot: false, // Hide the "all-day" row
  //   initialView: "timeGridDay", // Change the initial view to display hours
  //   themeSystem: "bootstrap",
  //   initialEvents: calendarEvents,
  //   eventOverlap: false,
  //   weekends: true,
  //   editable: true,
  //   selectable: true,
  //   selectOverlap:false,
  //   // slotEventOverlap:false,
  //   // select: this.handleEventClick.bind(this),
  //   // eventMouseLeave: this.handleEventClick.bind(this),
  //   eventMaxStack:60,
  //   selectMirror: true,
  //   dayMaxEvents: true,
  //   select: this.select.bind(this), // Adding the select callback
  //   dateClick: this.openModal.bind(this),
  //   // eventClick: this.handleEventClick.bind(this),
  //   // eventResize:this.handleEventClick.bind(this),
  //   // eventResizeStop:this.handleEventClick.bind(this),
  //   // eventLeave:this.handleEventClick.bind(this),
  //   eventResizableFromStart: true,
  //   displayEventTime:true,
  //   eventDragStop: this.handleEventClick.bind(this),
  //   eventsSet: this.handleEvents.bind(this),
  //   eventTimeFormat: {
  //     // like '14:30:00'
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     meridiem: false,
  //     hour12: true,
  //   },
  // };
  select(event){
    if(event.end){
      this.openModal(event)
    }
  }
  // currentEvents: EventApi[] = [];

    // Define the formSubmit group
    formSubmit: FormGroup;
    loading = false;
    // Define formSubmit controls for each field
    attendeesControl = this.fb.control(0, Validators.required);
    titleControl = this.fb.control("", Validators.required);
    phoneControl = this.fb.control("", Validators.required);
    otherTitleControl = this.fb.control("");
    firstNameControl = this.fb.control("", Validators.required);
    lastNameControl = this.fb.control("", Validators.required);
    nationalityControl = this.fb.control("", Validators.required);
    genderControl = this.fb.control("", Validators.required);
    emailAddressControl = this.fb.control("", [
      Validators.required,
      Validators.email,
    ]);
    formDataView :any
    organizationControl = this.fb.control("", Validators.required);
    jobTitleControl = this.fb.control("", Validators.required);
    roomControl = this.fb.control("", Validators.required);
    dateControl = this.fb.control("", Validators.required);
    timeControl = this.fb.control("", Validators.required);



  constructor(
    private modalService: BsModalService,
    private formBuilder: UntypedFormBuilder,
    private categoryService: CategoriesService,
    private router: Router,
    private roomsService: RoomsService,
    private fb: FormBuilder,
    private formService: FormService,
    private submitService: SubmitService,
    private eventService : CompanyEventsService
  ) {
      // Subscribe to changes in the date formSubmit control
      this.formSubmit = this.fb.group({
        attendees: this.attendeesControl,
        title: this.titleControl,
        otherTitle: this.otherTitleControl,
        firstName: this.firstNameControl,
        lastName: this.lastNameControl,
        nationality: this.nationalityControl,
        gender: this.genderControl,
        emailAddress: this.emailAddressControl,
  
        organization: this.organizationControl,
        jobTitle: this.jobTitleControl,
        room: this.roomControl,
        date: this.dateControl,
        time: this.timeControl,
        phone: this.phoneControl,
      });
      this.formSubmit.get("room").valueChanges.subscribe((room) => {
       
        this.selectedRoom = this.findRoomByUid(room);
  
        this.onRoomChange({
          target: {
            value: room,
          },
        });
        this.formSubmit.patchValue({
          date: this.minDate, // Replace with your desired value
        });
      
      });
  }

      
  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Skote' }, { label: 'Calendar', active: true }];

    this.formData = this.formBuilder.group({
      title: ['', [Validators.required]],
      category: ['', [Validators.required]],
    });

    this.formEditData = this.formBuilder.group({
      editTitle: ['', [Validators.required]],
      editCategory: [],
    });
    this._fetchData();
    this.loading = true
    this.getRooms(this.eventID);
    const formID = 'Qz8Zxm5VFMmE56z9YNrn'
    this.eventService.getEventByID(this.eventID).then((data)=>{
      this.event = data.data()
      console.log({event:this.event})
    })
    const formInstance = this.formService.getFormByID(formID)
     formInstance.then((data)=>{
      this.formDataView = data.data()
      console.log({formData:this.formData})
    })
    this.selectedRoom = this.rooms[0];
    // this.calendarOptions = {
    //   ...this.calendarOptions,
    //   validRange: {
    //     start: this.formatDateFromSeconds(this.rooms[0].date[0]), // Replace with your start date
    //     end: this.formatDateFromSeconds(this.rooms[0].date[1], 1), // Replace with your end date
    //   },
    //   hiddenDays: [], // 0 for Sunday, 6 for Saturday. Hide specific days of the week
    //   slotMinTime: `${this.rooms[0].startTime}:00`, // Replace with your minimum time selectedRoom
    //   slotMaxTime: `${this.rooms[0].endTime}:00`, // Replace with your maximum time
    // };
  }

  /**
   * Event click modal show
   */
  // handleEventClick(clickInfo: EventClickArg) {
  //   this.editEvent = clickInfo.event;
  //   this.formEditData = this.formBuilder.group({
  //     editTitle: clickInfo.event.title,
  //     editCategory: clickInfo.event.classNames[0],
  //   });
  //   this.modalRef = this.modalService.show(this.editmodalShow);
  // }

  /**
   * Events bind in calander
   * @param events events
   */
  // handleEvents(events: EventApi[]) {
  //   this.currentEvents = events;
  // }

  separateDialCode = true;
  searchCountryField = SearchCountryField;
  countryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  minDate: any;
  maxDate: any;
  preferredCountries: CountryISO[] = [
    CountryISO.Qatar, // Default preferred countries
    // Add more default preferred countries here
  ];
  selectedRoom: any;

  rooms = [];
  hours = [];
  eventID = "CqaRBvUembpsz1rxyrPU";
  event :any
  nationalities = nationalities
  titles = titles

  get form() {
    return this.formData.controls;
  }

  /**
   * Delete-confirm
   */
  confirm() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.deleteEventData();
        Swal.fire('Deleted!', 'Event has been deleted.', 'success');
      }
    });
  }

  position() {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Event has been saved',
      showConfirmButton: false,
      timer: 1000,
    });
  }

  /**
   * Event add modal
   */
  openModal(event?: any) {
    this.newEventDate = event;
    this.modalRef = this.modalService.show(this.modalShow);
  }

  /**
   * save edit event data
   */
  editEventSave() {
    const editTitle = this.formEditData.get('editTitle').value;
    const editCategory = this.formEditData.get('editCategory').value;
    
    const editId = this.calendarEvents.findIndex(
      (x) => x.id + '' === this.editEvent.id + ''
    );

    this.editEvent.setProp('title', editTitle);
    this.editEvent.setProp('classNames', editCategory);

    this.calendarEvents[editId] = {
      ...this.editEvent,
      title: editTitle,
      id: this.editEvent.id,
      classNames: editCategory + ' ' + 'text-white',
    };

    this.position();
    this.formEditData = this.formBuilder.group({
      editTitle: '',
      editCategory: '',
    });
    this.modalService.hide(1);
  }

  /**
   * Delete event
   */
  deleteEventData() {
    this.editEvent.remove();
    this.modalService.hide(1);
  }

  /**
   * Close event modal
   */
  closeEventModal() {
    this.formData = this.formBuilder.group({
      title: '',
      category: '',
    });
    this.modalService.hide(1);
  }

  /**
   * Save the event
   */
  saveEvent() {
    if (this.formData.valid) {
      const title = this.formData.get('title').value;
      const className = this.formData.get('category').value;
      const calendarApi = this.newEventDate.view.calendar;
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: this.newEventDate.date,
        end: this.newEventDate.date,
        className: className + ' ' + 'text-white'
      });
      this.position();
      this.formData = this.formBuilder.group({
        title: '',
        category: '',
      });
      this.modalService.hide(1);
    }
    this.submitted = true;
  }

  /**
   * Fetches the data
   */
  private _fetchData() {
    // Event category
    this.category = category;
    // Calender Event Data
    this.calendarEvents = calendarEvents;
    // form submit
    this.submitted = false;
  }



  countryChange(e) {}

  async getRooms(eventID) {
    try {
      const roomsArray = await this.roomsService.getRoomsCollectionByEvent(
        eventID
      );

      this.rooms = [];
      roomsArray.docs
        .map((doc) => {
          const roomData = doc.data();
          if (roomData?.deleted != true) {
            console.log(roomData);
        
            this.rooms.push(roomData);
          }
          // Assuming your room data contains a 'roomName' property
        })
        .reverse();

      if (this.rooms.length > 0) {
        console.log(this.rooms);
        console.log(this.rooms[0].date[0].seconds);
        this.formSubmit.patchValue({
          room: this.rooms[0].uid, // Replace with your desired value
        });
        this.onRoomChange({
          target: {
            value: this.rooms[0].uid,
          },
        });
      }

      this.selectedRoom = this.rooms[0];
      // this.calendarOptions = {
      //   ...this.calendarOptions,
      //   validRange: {
      //     start: this.formatDateFromSeconds(this.rooms[0].date[0]), // Replace with your start date
      //     end: this.formatDateFromSeconds(this.rooms[0].date[1], 1), // Replace with your end date
      //   },
      //   hiddenDays: [], // 0 for Sunday, 6 for Saturday. Hide specific days of the week
      //   slotMinTime: `${this.rooms[0].startTime}:00`, // Replace with your minimum time selectedRoom
      //   slotMaxTime: `${this.rooms[0].endTime}:00`, // Replace with your maximum time
      // };

    } catch (error) {
      console.error("Error fetching rooms:", error);
      this.rooms = []; // Set an empty array in case of an error
    }
  }

  onRoomChange(event: any) {
    // Retrieve the selected value from the event object
    this.selectedRoom = this.findRoomByUid(event.target.value);
    // alert(event.target.value)

    // Calculate the minDate and maxDate based on the selected room's date values
    this.minDate = this.formatDate(this.selectedRoom.date[0].seconds); // Start date timestamp
    this.maxDate = this.formatDate(this.selectedRoom.date[1].seconds); // End date timestamp

    // Get the current date as a formatted string
    const currentDate = this.formatDate(Date.now() / 1000); // Current date timestamp

    // Set minDate to currentDate if it's in the past
    this.minDate = this.minDate < currentDate ? currentDate : this.minDate;
    let hours = this.selectableHours(
      this.selectedRoom.startTime,
      this.selectedRoom.endTime
    );
    this.hours = []
    hours.forEach((hour) => {
      if (this.isDateHourFull(hour, this.minDate)) this.hours.push(hour);
    });
    // this.calendarOptions = {
    //   ...this.calendarOptions,
    //   validRange: {
    //     start: this.formatDateFromSeconds(this.selectedRoom.date[0]), // Replace with your start date
    //     end: this.formatDateFromSeconds(this.rooms[0].date[1], 1), // Replace with your end date
    //   },
    //   // hiddenDays: [0, 6], // 0 for Sunday, 6 for Saturday. Hide specific days of the week
    //   slotMinTime: `${this.rooms[0].startTime}:00`, // Replace with your minimum time selectedRoom
    //   slotMaxTime: `${this.rooms[0].endTime}:00`, // Replace with your maximum time
    // };
  
  }

  findRoomByUid(uid: string): any {
    return this.rooms.find((room) => room.uid === uid);
  }

  formatDate(timestamp: number): string {
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }
  selectableHours(startTime: any, endTime: any): number[] {
    const hours = [];
    for (
      let i = this.timeToInteger(startTime);
      i <= this.timeToInteger(endTime);
      i++
    ) {
      hours.push(i.toString());
    }
    return hours;
  }
  timeToInteger(timeString) {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours;
  }
  integerToTime(hours: number): string {
    // Ensure the hours are within a valid range (0-23)
    hours = Math.min(23, Math.max(0, hours));

    // Format the hours as two digits
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;

    // Return the formatted time string with minutes set to "00"
    return `${formattedHours}:00`;
  }

  toggleOtherTitleField() {
    // Determine whether to show the "Other Title" field based on the selected value
    const selectedTitle = this.formSubmit.get("title").value;
    if (selectedTitle === "other") {
      this.formSubmit.get("otherTitle").enable(); // Show the field
    } else {
      this.formSubmit.get("otherTitle").disable(); // Hide the field
    }
  }

  showOtherTitleField() {
    // Check if the "Other Title" field should be shown
    return this.formSubmit.get("title").value === "other";
  }

  isDateHourFull(hour, date): boolean {
    const data = this.selectedRoom;
    const key = `${date}:${hour}`;
    console.log("key :" + key);
    if (data[key] !== undefined && data.capacity !== undefined) {
      console.log("defined");
      return data[key] < data.capacity;
    }
    console.log("undefined");
    console.log(key);
    console.log(data);
    console.log(data[key]);
    return true;
  }
  async onSubmit() {
    if (this.formSubmit.valid) {
      // Form is valid, proceed with submission
      const formData = this.formSubmit.value;
      try {
        this.loading = true;
        const db = firebase.firestore();
        const ref = db.collection("submits").doc();
        const id = ref.id;

        const room = this.findRoomByUid(formData.room);

        const data = {
          data: { ...formData, roomName: room.roomName,code:this.event.code+this.formDataView.submitsCounter.submits },
          eventID: this.eventID,
          formID: "Qz8Zxm5VFMmE56z9YNrn",
          uid: id,
      
        };
        console.log({data})
        const hasCapacity = await this.roomsService.checkCapacity(
          room.uid,
          this.timeToInteger(formData.time),
          formData.date
        );
        if (hasCapacity) {
          await this.formService.submitForm(data);
          await this.roomsService.incrementRoom(
            room.uid,
            this.timeToInteger(formData.time),
            formData.date
          );

          const categoryRef = await this.categoryService.getCategoriesByID(
            "02MzaoSyJfZiZWtmbSxu"
          );
          const category = categoryRef.data();
          console.log(category);
          // Send confirmation email
          await this.submitService.sendEmail(
            data.data,
            "register",
            category,
            category,id
          );

          // 02MzaoSyJfZiZWtmbSxu
          this.loading = false;
         
          this.router.navigate([
            "/thankyou",
            {
              data: data.data,
              typ1: category?.typ1
                ? category?.typ1
                : "Registration Successful",
              typ2:category?.typ2
                ? category?.typ2
                : "Thank you, your registration has been successful. Our team will contact you within 24-48 hours.",
            },
          ]);
        } else {
          alert("full");
        }
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    } else {
      // Form is invalid, show error messages
      console.log("invalid");
      this.formSubmit.markAllAsTouched();

      // Loop through formSubmit controls and log error messages
      Object.keys(this.formSubmit.controls).forEach((controlName) => {
        const control = this.formSubmit.get(controlName);
        if (control.invalid) {
          console.log(`Field '${controlName}' has errors:`);
          const errors = control.errors;
          Object.keys(errors).forEach((errorName) => {
            console.log(`- ${errorName}: ${JSON.stringify(errors[errorName])}`);
          });
        }
      });
    }
  }
  formatDateFromSeconds(
    obj: { seconds: number },
    additionalDays: number = 0
  ): string {
    const milliseconds = obj.seconds * 1000; // Convert seconds to milliseconds

    const date = new Date(milliseconds);
    date.setDate(date.getDate() + additionalDays); // Add the specified number of days

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  onAttendeesChange(event: any) {
    const selectedAttendees = event.target.value; // Extract the selected number of attendees from the event
  
    // Assuming you have an array of rooms with a 'capacity' property
    const availableRooms = this.rooms.filter(room => room.capacity >= selectedAttendees);
  
    this.rooms = availableRooms
    // You can update the available rooms list in your form or do other necessary operations
  }
}
