import { Injectable } from "@angular/core";
import { getFirebaseBackend, firebase } from "../../authUtils";
import { UserX } from "../models/users";
import { AuthenticationService } from "./auth.service";
import { CompanyEventsService } from "./company-events.service";
import { MindeeService } from "./mindee.service";

@Injectable({
  providedIn: "root",
})
export class FormService {
  constructor(
    private authService: AuthenticationService,
    private eventService: CompanyEventsService,
    private mindeeService:MindeeService
  ) {}
  getFormsDocuments() {
    let formRef = firebase.firestore().collection("/forms/");

    return formRef;
  }

  getFormsByEventID(id) {
    let user = this.authService.currentUserValue;
    let event = user.event;
    let forms = [];
    let events = [];
    return new Promise<any>(async (resolve, reject) => {
      await firebase
        .firestore()
        .collection("/forms/")
        .where("eventsIDs", "array-contains", id)
        .get()
        .then((result) => {
          result.forEach((data) => {
            let form = data.data();
            if (user.forms && user.forms.length > 0) {
              user.forms.forEach((element) => {
                if (element.uid == form.uid) {
                  
                  forms.push(form);
                }
              });
            } else {
              forms.push(form);
            }
          });

          resolve(forms);
        });
    });
  }

  getFormsByEvents() {
    let user = this.authService.currentUserValue;
    let event = user.event;
    let forms = [];
    let events = [];
    return new Promise<any>((resolve, reject) => {
      this.eventService.getEventsByUser().then((res: any) => {
        return new Promise<any>((resolve, reject) => {
          res.forEach(async (element) => {
            await firebase
              .firestore()
              .collection("/forms/")
              .where("eventsIDs", "array-contains", element.uid)
              .get()
              .then((result) => {
                result.forEach((data) => {
                  let form = data.data();
                  forms.push(form);
                });
                events.push(element);
                if (events.length == res.length) resolve(forms);
              });
          });
        }).then(() => {
          resolve(forms);
        });
      });
    });
  }

  getFormsByEventsInput(events) {
    let user = this.authService.currentUserValue;
    let event = user.event;
    let forms = [];

    return new Promise<any>((resolve, reject) => {
      if (events.companyID) {
        firebase
          .firestore()
          .collection("/forms/")
          .where("company.uid", "==", events.companyID)
          .get()
          .then((result) => {
            result.forEach((data) => {
              let form = data.data();
              forms.push(form);
            });
            resolve(forms);
          });
      } else if (events.length > 0) {
        events.forEach(async (element, i) => {
          await firebase
            .firestore()
            .collection("/forms/")
            .where("eventsIDs", "array-contains", element.uid)
            .get()
            .then((result) => {
              result.forEach((data) => {
                let form = data.data();
                forms.push(form);
              });
              //   events.push(element);
              if (i == events.length - 1) {
                resolve(forms);
                //   alert(JSON.stringify(forms))
              }
            });
        });
      } else {
        resolve([]);
      }
    });
  }

  getFormByID(id) {
    return firebase.firestore().collection("/forms/").doc(id).get();
  }

  isFormClosed(id) {
    return firebase.firestore().collection("/forms/").doc(id).get();
  }
  deleteForm(id) {
    return firebase.firestore().collection("/forms/").doc(id).set({deleted:true},{merge:true});
  }

  getFormsByCompanyID() {}
  updateForm(id, data) {
    return firebase
      .firestore()
      .collection("/forms/")
      .doc(id)
      .update({ ...data }, { merge: true })
      .then((response: any) => {
      
        const form = response;
        return form;
      });
  }
  createForm(data) {
    let user = this.authService.currentUserData();
    const db = firebase.firestore();
    const ref = db.collection("forms").doc();
    const id = ref.id;
    const createdAt = new Date().getTime();

    data?.inputs?.map((data) => {
      if (data.key == "file") {
        data.storage = "base64";
      }
    });

    return db
      .collection("/forms/")
      .doc(id)
      .set({ ...data, uid: id, createdAt: createdAt, createdBy: user.uid })
      .then((response: any) => {
        const form = response;
        return form;
      });
  }
  statusCounterUpdate(id, type, incrementAmount) {
    const documentRef = firebase.firestore().collection("/forms/").doc(id);
    const increment = firebase.firestore.FieldValue.increment(incrementAmount);
    const varName = `submitsCounter.${type}`;
    return documentRef.update({
      [varName]: increment,
    });
  }
  statusCounterEventUpdate(id, type, incrementAmount) {
    const documentRef = firebase.firestore().collection("/events/").doc(id);
    const increment = firebase.firestore.FieldValue.increment(incrementAmount);
    const varName = `submitsCounter.${type}`;
    return documentRef.update({
      [varName]: increment,
    });
  }
  submitForm(data) {
    return new Promise<any>((resolve, reject) => {
      data.status = "pending";
      // let user = this.authService.currentUserData();
      const db = firebase.firestore();
      const ref = db.collection("forms").doc();
      const refID = ref.id;
      let id = data.uid ? data.uid : refID;
      const createdAt = new Date().getTime();
   
      return db
        .collection("/submits/")
        .doc(id)
        .set({
          ...data,
          uid: id,
          createdAt: createdAt,
          // createdBy: user?.uid ? user?.uid : "",
        })
        .then(async (response: any) => {
          try {
            if(data?.passportPictureURL){
              this.mindeeService.checkPassportServerSide(id,data?.passportPictureURL)
            }
          } catch (error) {
            
          }
          try {
            if(data?.profilePictureURL){
              this.mindeeService.checkProfileServerSide(id,data?.profilePictureURL)
            }
          } catch (error) {
            
          }
          try {
            await this.statusCounterUpdate(data.formID,'submits',1)
            await this.statusCounterEventUpdate(data.eventID,'submits',1)
            await this.statusCounterUpdate(data.formID,'pending',1)
          } catch (error) {
            
          }
        
          resolve({
            ...data,
            uid: refID,
            createdAt: createdAt,
            // createdBy: user?.uid ? user?.uid : "",
          });
        });
    });
  }
  submitFormApproved(data) {
    return new Promise<any>((resolve, reject) => {
      data.status = "approved";
      // let user = this.authService.currentUserData();
      const db = firebase.firestore();
      const ref = db.collection("forms").doc();
      const refID = ref.id;
      let id = data.uid ? data.uid : refID;
      const createdAt = new Date().getTime();
   
      return db
        .collection("/submits/")
        .doc(id)
        .set({
          ...data,
          uid: id,
          createdAt: createdAt,
          // createdBy: user?.uid ? user?.uid : "",
        })
        .then(async (response: any) => {
          try {
            if(data?.passportPictureURL){
              this.mindeeService.checkPassportServerSide(id,data?.passportPictureURL)
            }
          } catch (error) {
            
          }
          try {
            if(data?.profilePictureURL){
              this.mindeeService.checkProfileServerSide(id,data?.profilePictureURL)
            }
          } catch (error) {
            
          }
          try {
            await this.statusCounterUpdate(data.formID,'submits',1)
            await this.statusCounterEventUpdate(data.eventID,'submits',1)
            await this.statusCounterUpdate(data.formID,'approved',1)
          } catch (error) {
            
          }
        
          resolve({
            ...data,
            uid: refID,
            createdAt: createdAt,
            // createdBy: user?.uid ? user?.uid : "",
          });
        });
    });
  }
  canRead(user: UserX): boolean {
    const allowed = ["owner", "admin", "user", "mofa"];
    return this.checkAuthorization(user, allowed);
  }

  canEdit(user: UserX): boolean {
    const allowed = ["owner", "admin", "mofa"];
    return this.checkAuthorization(user, allowed);
  }

  canDelete(user: UserX): boolean {
    const allowed = ["owner"];
    return this.checkAuthorization(user, allowed);
  }
  canCreate(user: UserX): boolean {
    const allowed = ["owner"];
    return this.checkAuthorization(user, allowed);
  }

  checkAuthorization(user: UserX, allowedRoles: string[]): boolean {
    if (!user) return false;
    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true;
      }
    }
    return false;
  }
}
