import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Injector,
  OnInit,
  Renderer2,
} from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from "ngx-intl-tel-input";

import { CategoriesService } from "src/app/core/services/categories.service";
import { CompanyEventsService } from "src/app/core/services/company-events.service";
import { FormService } from "src/app/core/services/form.service";
import { RoomsService } from "src/app/core/services/rooms.service";
import { SubmitService } from "src/app/core/services/submits.service";
import { TranslationsService } from "src/app/core/services/translations.service";
import { firebase } from "src/app/fbInitialize";
import {
  registerCustomComponent,
  registerCustomComponentCropImage,
  registerCustomComponentEventRoomsPicker,
  registerCustomComponentRoomPicker,
  registerCustomComponentTimePicker,
} from "src/app/formio/rating-wrapper.formio";
import AWS from "aws-sdk";
import values from "./countries.translations";
import { MindeeService } from "src/app/core/services/mindee.service";
import { DOCUMENT } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormComponent implements OnInit, AfterViewInit {
  constructor(
    private route: ActivatedRoute,
    private injector: Injector,
    private router: Router,
    private mindeeService: MindeeService,
    private formService: FormService,
    private submitService: SubmitService,
    private eventService: CompanyEventsService,
    private categoryService: CategoriesService,
    private translationService: TranslationsService,
    private translateService: TranslateService,
    private translate: TranslateService,
    private roomsService: RoomsService,
    private renderer: Renderer2,
    private el: ElementRef,
    private cdRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    private sanitizer: DomSanitizer
  ) {
    try {
      registerCustomComponent(injector);
      registerCustomComponentCropImage(injector);
      registerCustomComponentTimePicker(injector);
      registerCustomComponentRoomPicker(injector);
      registerCustomComponentEventRoomsPicker(injector);
    } catch (error) {}
  }
  private styleElement: HTMLStyleElement;
  roomID: string;
  eventID = "JX8mA3R9gKYUH6CkQaiv";
  formID: string;
  categoryID: string;
  separateDialCode = true;
  searchCountryField = SearchCountryField;
  countryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  scannedData: any;
  public editFormID: any;
  formData: any;
  // @ViewChild("formio") formio: any;
  breadCrumbItems: Array<{}>;
  form: any;
  submitted = false;
  error = "";
  successmsg = false;
  inputs: Array<{}>;
  formx: any;
  profile: any;
  passport: any;
  loading = true;
  oneoftwo: true;
  event: any;
  font: any;
  language = "english";
  lang: any;
  languageFromURL: any = "eng";
  mainSuper: any;
  hasCategory = false;
  category: any;
  stylesObj: any;
  isClosed = false;
  categoryData: any = {
    disabled: false,
  };
  selectedRoom: any;
  room: any;
  rooms = [];
  countryNames: any = {}; // Store translated country names

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.route.queryParams.subscribe(async (params) => {
      if (params["formid"]) this.formID = params["formid"];
      if (params["categoryid"]) this.categoryID = params["categoryid"];
      if (params["roomID"]) this.roomID = params["roomID"];
      console.log({ params });
      if (params["lang"]) this.languageFromURL = params["lang"];

      const res: any = await this.formService.getFormByID(this.editFormID);
      this.formx = res.data();

      if (this.roomID)
        this.roomsService.getRoomByID(this.roomID).then((data) => {
          this.room = data.data();
          //  if(this.room.form.uid){
          //   this.formID = this.room.form.uid
          //  }
          this.loading = false;
        });
    });

    this.stylesObj = { "font-family": "FontAwesome", src: this.font };
    const routeParams = this.router.routerState.snapshot;
    const urlSegments = routeParams.url.split("/");
    const length = urlSegments.length;

    await this.getCategoryData(this.categoryID);

    // if (length >= 4) {
    //   if (urlSegments[length - 1]) {
    //     const categoryID = urlSegments[length - 1];
    //     await getCategoryData(categoryID);
    //   }
    //   this.editFormID = urlSegments[length - 2];
    // } else if (length == 5) {
    //   if (urlSegments[length - 1]) {
    //     this.languageFromURL = urlSegments[length - 1];
    //     const categoryID = urlSegments[length - 2];
    //     await getCategoryData(categoryID);
    //   }
    //   this.editFormID = urlSegments[length - 3];
    // } else {
    //   this.editFormID = urlSegments[length - 1];
    // }

    this.breadCrumbItems = [
      { label: "Admin" },
      { label: "Users" },
      { label: "Edit User", active: true },
    ];

    try {
      this.loading = true;
      // alert(this.formID);
      const res: any = await this.formService.getFormByID(this.formID);
      this.formx = res.data();
      console.log({ st: this.formx.styles });

      // const classes = this.formx.styles.split(' ');
      // classes.forEach(className => {
      //   this.renderer.addClass(this.el.nativeElement, className);
      // });
      try {
        this.eventID = this.formx?.eventID; // Replace with your event ID
        this.eventService.getEventByID(this.eventID).then((data) => {
          this.event = data.data();
          if (this.languageFromURL === "ar") {
            this.font = this.event.font_url_AR;
            console.log({ font: this.font });
            if (this.font) {
              var f = new FontFace("fingerpaint", `url(${this.font})`, {});
              document.fonts.add(f);
              document.getElementById("font").style.fontFamily = "fingerpaint";
            }
          } else {
            this.font = this.event.font_url;
            console.log({ font: this.font });
            if (this.font) {
              var f = new FontFace("fingerpaint", `url(${this.font})`, {});
              document.fonts.add(f);
              document.getElementById("font").style.fontFamily = "fingerpaint";
            }
          }

          //
        });
        localStorage.setItem("eventID", this.eventID);
      } catch (error) {
        this.loading = false;

        // Handle the error, e.g., show an error message
      } finally {
        this.loading = false;
      }

      let translationsArray = await this.translationService
        .getTranslationsCollection()
        .get()
        .then((data) => {
          return data.docs.map((doc) => doc.data());
        })
        .catch((error) => {
          return [];
        });

      translationsArray = [...translationsArray, ...values];
      if (this.languageFromURL === "ar") {
        //
        //
        this.formx.inputs = this.translateLabels(
          this.formx.inputs,
          translationsArray
        );
        // Apply RTL class to Formio

        const formioElement = document.querySelector(".formio");
        if (formioElement) {
          formioElement.classList.add("rtl-formio padding-right-5");
        }
        this.language = "lt";
        this.lang = {
          language: "lt",
          i18n: {
            lt: {
              "is required": "مطلوب",
              "is required ": "مطلوب",
              browse: "تصفح",
              "Enter Phone no": "أدخل رقم الهاتف",
              "Type to search": "اكتب للبحث",
              or: "أو",
              "Drop files to attach,": "قم بإسقاط الملفات لإرفاقها ",
              // Add other translations
            },
          },
        };
        // Load translations here
        this.translateService
          .get("countryCodes")
          .subscribe((translations: any) => {
            this.countryNames = translations;
          });

        // Check if the language is 'ar'

        if (formioElement) {
          formioElement.classList.add("rtl-formio p-2");
        }
        this.loading = false;
        // Switch to the Arabic language
        this.translate.use("ar");
      }
      if (this.languageFromURL === "fr") {
        //
        //
        this.formx.inputs = this.translateLabels(
          this.formx.inputs,
          translationsArray
        );
        // Apply RTL class to Formio

        const formioElement = document.querySelector(".formio");

        this.language = "lt";
        this.lang = {
          language: "lt",
          i18n: {
            lt: {
              "is required": "est requis",
              "is required ": "est requis",
              browse: "Parcourir",
              "Enter Phone no": "Entrez le numéro de téléphone",
              "Type to search": "Tapez pour rechercher",
              or: "ou",
              "Drop files to attach,": "Déposez les fichiers à joindre,",
              // Add other translations
            },
          },
        };

        // Load translations here
        this.translateService
          .get("countryCodes")
          .subscribe((translations: any) => {
            this.countryNames = translations;
          });

        // Check if the language is 'ar'
        this.loading = false;
        // Switch to the Arabic language
        this.translate.use("fr");
      }
      console.log(this.formx);
      if (this.formx?.inputs) {
        this.form = {
          components: this.formx.inputs,
        };
      }
      this.loadDynamicStyles(this.formx?.styles);
    } catch (error) {
      console.log({ error });
      // Handle error
    }
  }

  ngAfterViewInit() {
    // this.applyDynamicStyles(":host ::ng-deep .formio-component-textfield .form-control { color: blue; background-color: lightgrey; border: 1px solid green; }");
  }

  private loadDynamicStyles(cssString: string) {
    const styleElement = this.renderer.createElement("style");
    this.renderer.setProperty(styleElement, "textContent", cssString); // Use textContent for security over innerHTML
    this.renderer.appendChild(this.document.head, styleElement);
  }

  getCategoryData = async (categoryID: string) => {
    try {
      const data = await this.categoryService.getCategoriesByID(categoryID);
      this.categoryData = data.data();
      this.category = this.categoryData.name;
    } catch (error) {
      // Handle error
    }
  };

  findRoomByUid(uid: string): any {
    return this.rooms.find((room) => room.uid === uid);
  }

  formatDate(timestamp: number): string {
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }
  selectableHours(startTime: any, endTime: any): number[] {
    const hours = [];
    for (
      let i = this.timeToInteger(startTime);
      i <= this.timeToInteger(endTime);
      i++
    ) {
      hours.push(i.toString());
    }
    return hours;
  }
  timeToInteger(timeString) {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours;
  }
  integerToTime(hours: number): string {
    // Ensure the hours are within a valid range (0-23)
    hours = Math.min(23, Math.max(0, hours));

    // Format the hours as two digits
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;

    // Return the formatted time string with minutes set to "00"
    return `${formattedHours}:00`;
  }

  toggleOtherTitleField() {
    // Determine whether to show the "Other Title" field based on the selected value
    const selectedTitle = this.form.get("title").value;
    if (selectedTitle === "other") {
      this.form.get("otherTitle").enable(); // Show the field
    } else {
      this.form.get("otherTitle").disable(); // Hide the field
    }
  }

  showOtherTitleField() {
    // Check if the "Other Title" field should be shown
    return this.form.get("title").value === "other";
  }
  numberToFourDigits(number) {
    return number.toString().padStart(4, "0");
  }

  handleUpload(
    photoType: string,
    urlKey: string,
    fileName: string,
    data: any,
    id: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        let varName = "";
        for (const [key, value] of Object.entries(data.data)) {
          if (key.includes("personal")) {
            varName = key;
            break;
          }
        }
        console.log(data.data[varName][photoType]);
        console.log(320);
        const base64Data = data.data[varName][photoType][0].url
          .split("base64,")
          .pop();
        console.log({ base64Data });
        const mimeType = this.getFileTypeFromBase64(base64Data); // Adjust the mime type accordingly
        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
          const slice = byteCharacters.slice(offset, offset + 1024);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const photoBlob = new Blob(byteArrays, { type: mimeType });
        const filePath =
          fileName === "PP"
            ? `passports/${id}_${fileName}.jpg`
            : `profiles/${id}_${fileName}.jpg`;

        const s3 = new AWS.S3({
          accessKeyId: "AKIA5J6SD6XVX4LRHKIW",
          secretAccessKey: "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",
          region: "eu-central-1",
        });

        const params = {
          Bucket: "diggri",
          Key: filePath,
          Body: photoBlob,
          ContentType: mimeType,
        };

        s3.upload(params, async (err, res) => {
          if (err) {
            reject(err);
          } else {
            // if (photoType === "passportPhoto") {
            //   let scannedData = await this.mindeeService
            //     .scanPassport(res.Location)
            //     .catch((error) => {
            //       this.loading = false;
            //       alert("Something went wrong");
            //     });

            //   this.scannedData = scannedData;
            //   data.scannedData = scannedData;
            // }
            delete data.data[varName][photoType];

            if (photoType === "profilePhoto") {
              data.data[varName].profilePictureURL = res.Location;
              data.profilePictureURL = res.Location;
            }
            if (photoType === "passportPhoto") {
              data.data[varName].passportPictureURL = res.Location;
              data.passportPictureURL = res.Location;
            }
            // if (data.data[varName][photoType])
            // data[urlKey] = data.data[varName][photoType];

            resolve(res.Location);
          }
        });
      } catch (error) {
        resolve();
      }
    });
  }

  async onSubmit(formData: any) {
    this.loading = true;
    const db = firebase.firestore();
    const ref = db.collection("submits").doc();
    const id = ref.id;
    const data = {
      ...formData,
      uid: id,
    };

    let varName = "";
    let boolProfile = false;
    let boolPassport = false;

    const uploadTasks = [];
    for (const [key, value] of Object.entries(data.data)) {
      if (key.includes("personal")) {
        varName = key;
        const innerValue = data.data[key];
        boolProfile = innerValue && innerValue.hasOwnProperty("profilePhoto");
        boolPassport = innerValue && innerValue.hasOwnProperty("passportPhoto");
        break;
      }
    }
    if (boolProfile || boolPassport) {
      uploadTasks.push(
        this.handleUpload(
          "passportPhoto",
          "passportPictureURL",
          "PP",
          data,
          id
        ),
        this.handleUpload("profilePhoto", "profilePictureURL", "PH", data, id)
      );
    } else if (boolPassport) {
      let varName = "";
      for (const [key, value] of Object.entries(data.data)) {
        if (key.includes("personal")) {
          varName = key;
          break;
        }
      }

      uploadTasks.push(
        this.handleUpload("passportPhoto", "passportPictureURL", "PP", data, id)
      );
    } else if (boolProfile) {
      uploadTasks.push(
        this.handleUpload("profilePhoto", "profilePictureURL", "PH", data, id)
      );
    }

    try {
      Promise.all(uploadTasks)
        .then((data: any) => {
          data.formID = this.formID;
          data.eventsIDs = this.formx.eventsIDs;
          data.eventID = this.formx.eventsIDs[0];
          data.company = this.formx.company;
          this.replaceUndefinedWithEmptyString(data);
          return data;
        })
        .then((finalData) => {
          this.eventService.getEventByID(this.eventID).then(async (res) => {
            let categoryRef: any;
            let category: any;
            if (this.categoryID) {
              categoryRef = await this.categoryService.getCategoriesByID(
                this.categoryID
              );
              category = categoryRef.data();
            }
            this.event = res.data()
            const submitNumber = this?.event?.submitsCounter?.submits | 0;
            let data: any = {
              passportPictureURL: finalData["0"] ? finalData["0"] : "",
              profilePictureURL: finalData["1"] ? finalData["1"] : "",
              categoryID: this.categoryID,
              data: {
                category: category?.name,
                categoryClass: category?.categoryClass
                  ? category?.categoryClass
                  : "",
                usedLanguage: this.languageFromURL,
                passportPictureURL: finalData["0"] ? finalData["0"] : "",
                profilePictureURL: finalData["1"] ? finalData["1"] : "",
                ...formData.data,
                code: `${this.event.code}-${this.numberToFourDigits(
                  submitNumber + 1
                )}`,
              },
              eventID: this.eventID,
              //   formID: "At1A1rR1eoRc6NPeXe0a",
              uid: id,
              formID: this.formID,
              eventsIDs: this.formx.eventsIDs,
              // company: this.formx.company,
            };
            if (this.scannedData) {
              data.data.scannedData = {
                ...data?.data?.scannedData,
                ...this.scannedData,
              };
            }

            let hasCapacity = true;
            if (this.roomID)
              hasCapacity = await this.roomsService.checkCapacity(
                this.roomID,
                this.timeToInteger(
                  formData?.data?.personalInformation1?.selectTimeSlot
                    ?.selectedTime
                ),
                formData?.data?.personalInformation1?.selectTimeSlot
                  ?.selectedDate
              );

            if (hasCapacity) {
              await this.formService.submitForm(data);

              if (this.roomID) {
                await this.roomsService.incrementRoom(
                  this.roomID,
                  this.timeToInteger(
                    formData.data.personalInformation1?.selectTimeSlot
                      ?.selectedTime
                  ),
                  formData.data.personalInformation1?.selectTimeSlot
                    ?.selectedDate
                );
              }

              if (this.categoryID) {
                // Send confirmation email
                const emailData = {
                  ...data.data?.scannedData,
                  ...data.data.personalInformation1,
                  language: this.languageFromURL,
                  code: data.data.code,
                  url:
                    "https://diggri.com/thankyou/" +
                    category?.tyPage?.uid +
                    "/" +
                    id,
                  ...data.data?.personalInformation1?.selectTimeSlot,
                };
                await this.submitService.sendEmail(
                  emailData,
                  "register",
                  category,
                  this.event,
                  formData.uid
                );
              }

              if (category?.tyPage?.uid) {
                this.router.navigate([
                  "/thankyou/" + category?.tyPage?.uid + "/" + id,
                ]);
              } else
                this.router.navigate([
                  "/thankyou",
                  {
                    typ1: category?.typ1
                      ? category?.typ1
                      : "Registration Successful",
                    typ2: category?.typ2
                      ? category?.typ2
                      : "Thank you, your registration has been successful. Our team will contact you within 24-48 hours.",
                  },
                ]);

              this.loading = false;
            } else {
              alert("full");
            }

            this.loading = false;
          });
        });
    } catch (error) {
      this.loading = false;
    }
  }

  replaceUndefinedWithEmptyString(obj) {
    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        this.replaceUndefinedWithEmptyString(obj[key]); // Recursively check nested objects
      } else if (
        typeof obj[key] === "undefined" ||
        !obj[key] ||
        obj[key] === null ||
        obj[key] === ""
      ) {
        obj[key] = ""; // Replace undefined with an empty string
      }
    }
  }

  isDateHourFull(hour, date): boolean {
    const data = this.selectedRoom;
    const key = `${date}:${hour}`;

    if (data[key] !== undefined && data.capacity !== undefined) {
      return data[key] < data.capacity;
    }

    return true;
  }

  translateLabels(components: any[], translations: any[]) {
    components.forEach((component: any) => {
      if (component.label) {
        const matchingTranslation = translations.find((translation) => {
          return (
            translation.name.toLowerCase().replace(/\s/g, "") ===
            component.label.toLowerCase().replace(/\s/g, "")
          );
        });
        console.log({ matchingTranslation });
        if (matchingTranslation) {
          component.label = matchingTranslation[this.languageFromURL]; // Replace with the translated label
        }
      }
      if (component?.validate?.customMessage) {
        const matchingTranslation = translations.find((translation) => {
          return (
            translation.name.toLowerCase().replace(/\s/g, "") ===
            component.validate.customMessage.toLowerCase().replace(/\s/g, "")
          );
        });
        if (matchingTranslation) {
          component.validate.customMessage =
            matchingTranslation[this.languageFromURL]; // Replace with the translated label
        }
      }
      if (component.placeholder) {
        const matchingTranslation = translations.find(
          (translation) =>
            translation.name.toLowerCase().replace(/\s/g, "") ===
            component.placeholder.toLowerCase().replace(/\s/g, "")
        );
        if (matchingTranslation) {
          component.placeholder = matchingTranslation[this.languageFromURL]; // Replace with the translated placeholder
        }
      }

      if (component.description) {
        const matchingTranslation = translations.find(
          (translation) =>
            translation.name.toLowerCase().replace(/\s/g, "") ===
            component.description.toLowerCase().replace(/\s/g, "")
        );
        if (matchingTranslation) {
          component.description = matchingTranslation[this.languageFromURL]; // Replace with the translated placeholder
        }
      }

      // Translate options if applicable
      if (component.data && component.data.values) {
        for (const option of component.data.values) {
          const matchingTranslation = translations.find(
            (translation) =>
              translation.name.toLowerCase().replace(/\s/g, "") ===
              option.label.toLowerCase().replace(/\s/g, "")
          );
          if (matchingTranslation) {
            option.label = matchingTranslation[this.languageFromURL]; // Replace with the translated option label
          }
        }
      }

      if (component.fields) {
        for (const key in component.fields) {
          if (component.fields.hasOwnProperty(key)) {
            const field = component.fields[key];
            if (field.placeholder) {
              const placeholderToMatch = field.placeholder
                .toLowerCase()
                .replace(/\s/g, "");
              const matchingTranslation = translations.find(
                (translation) =>
                  translation.name.toLowerCase().replace(/\s/g, "") ===
                  placeholderToMatch
              );
              if (matchingTranslation) {
                field.placeholder = matchingTranslation[this.languageFromURL]; // Replace with the translated placeholder
              }
            }
          }
        }
      }

      if (component.data && component.data.values) {
        component.data.values.forEach((value: any) => {
          if (value.label) {
            const labelToMatch = value.label.toLowerCase().replace(/\s/g, "");
            const matchingTranslation = translations.find(
              (translation) =>
                translation.name.toLowerCase().replace(/\s/g, "") ===
                labelToMatch
            );
            if (matchingTranslation) {
              value.label = matchingTranslation[this.languageFromURL]; // Replace with the translated label
            }
          }
        });
      }

      // Recursively traverse nested components
      if (component.components) {
        this.translateLabels(component.components, translations);
      }
      if (component.columns) {
        component.columns.forEach((column: any) => {
          if (column.components) {
            this.translateLabels(column.components, translations);
          }
        });
      }
    });

    return components;
  }

  onchange(event) {
    for (
      let index = 0;
      index < document.getElementsByClassName("fa-remove").length;
      index++
    ) {
      document.getElementsByClassName("fa-remove")[index].innerHTML = "X";
    }
  }

  getFileTypeFromBase64(base64String) {
    console.log("Base64 String:", base64String.substring(0, 50)); // Log the beginning of the string
    const regex = /^data:(.*?);base64,/; // Regular expression to extract MIME type
    const match = base64String.match(regex);
    console.log({ match });
    return match ? match[1] : null; // Returns the MIME type if matched, otherwise null
  }
}
