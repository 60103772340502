<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.8/css/intlTelInput.css" />


<div #pdfContent id="pdfContent">
  <div *ngIf="(!isClosed && !categoryData.disabled) && !loading" class="row" [ngStyle]="stylesObj">
    <div id="font">
      
      <div *ngIf="loading" tabindex="-1" style=" pointer-events: none;">
        <formio #formio id="formio" class="rtl-formio" [(renderOptions)]="lang" [form]="form"></formio>
      </div>
      <!-- <div *ngIf="!loading">
        <formio class="rtl-formio"   [(renderOptions)]="lang" [form]="form"></formio>
      </div> -->
      <div *ngIf="!loading && languageFromURL==='ar'">
        <formio #formio id="formio" class="rtl-formio" [(renderOptions)]="lang" [form]="form"></formio>
      </div>
      <div *ngIf="!loading && languageFromURL!='ar'">
        <formio #formio id="formio" [(renderOptions)]="lang" [form]="form" [submission]="submission"> </formio>
      </div>

    </div>

  </div>
  
</div>
<!-- <div *ngIf='!loading && templateType === "thank-you-pages"' class="d-flex align-items-center justify-content-center mb-5">
  <a class="btn btn-primary" (click)="generatePDF()">Download Confirmation</a>

</div> -->